import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import TacticTest from "./tacticTest";
import arrow from "../../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../shared/visitedPath";
import { AuthContext, PlanContext } from "../../app/context";

const Tactic = (props) => {
  // --------------- modal state ------------------
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { plan } = useContext(PlanContext);
  const { user } = useContext(AuthContext);
  const StrategicFocus = ["Focus 1", "Focus 2"];
  const [show, setShow] = useState(false);
  const [userGoals, setUserGoals] = useState();
  const [userFocus, setUserFocus] = useState();
  const [userPriorities, setUserPriorities] = useState();
  const [finalThemeData, setFinalThemeData] = useState([]);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    visitedPath(plan?._id, { title: "Tactical Plan", path: "/tacticalplan" });
  }, [location.pathname]);

  useEffect(() => {
    const getGoals = async () => {
      try {
        const response = await fetch(`${apiUrl}/goal/${plan._id}`);

        if (!response.ok) {
          console.log("Network response was not ok");
        }
        const json = await response.json();
        setUserGoals(json[0]?.selectedGoals);
      } catch (error) {
        console.log(error.message);
      }
    };
    getGoals();
  }, []);

  const getFinalThemeData = async () => {
    try {
      const response = await fetch(`${apiUrl}/priority/${plan._id}`);

      if (!response.ok) {
        console.log("Network response was not ok");
      }
      let json = await response.json();

      json = json.priority;

      // const Dropdown = json.selectedDropdownn

      const priorities = [
        json.priority1,
        json.priority2,
        json.priority3,
        json.priority4,
      ].filter(Boolean);

      const strategicGoals = [json.strategicGoal1, json.strategicGoal2].filter(
        Boolean
      );

      setUserPriorities(priorities);
      setUserFocus(strategicGoals);
    } catch (error) {
      console.log(error.message);
    }
  };
  const getFinalData = async () => {
    try {
      const response = await fetch(`${apiUrl}/finalTheme/${plan._id}`);

      if (!response.ok) {
        console.log("Network response was not ok");
      }
      let json = await response.json();
      // console.log(json)
      setFinalThemeData(json);
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log(userPriorities);

  useEffect(() => {
    getFinalThemeData();
    getFinalData();
  }, []);
  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Body className="modalBody">
          <div className="modal-header">
            <div className="titleModal">
              {" "}
              <span>Continue with</span>
            </div>
            <div className="modlclose">
              <span onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_1654_8171)"
                  />
                  <path
                    d="M11.5 12.6711L7.40114 16.77C7.24778 16.9233 7.0526 17 6.81559 17C6.57858 17 6.3834 16.9233 6.23004 16.77C6.07668 16.6166 6 16.4214 6 16.1844C6 15.9474 6.07668 15.7522 6.23004 15.5989L10.3289 11.5L6.23004 7.40114C6.07668 7.24778 6 7.0526 6 6.81559C6 6.57858 6.07668 6.3834 6.23004 6.23004C6.3834 6.07668 6.57858 6 6.81559 6C7.0526 6 7.24778 6.07668 7.40114 6.23004L11.5 10.3289L15.5989 6.23004C15.7522 6.07668 15.9474 6 16.1844 6C16.4214 6 16.6166 6.07668 16.77 6.23004C16.9233 6.3834 17 6.57858 17 6.81559C17 7.0526 16.9233 7.24778 16.77 7.40114L12.6711 11.5L16.77 15.5989C16.9233 15.7522 17 15.9474 17 16.1844C17 16.4214 16.9233 16.6166 16.77 16.77C16.6166 16.9233 16.4214 17 16.1844 17C15.9474 17 15.7522 16.9233 15.5989 16.77L11.5 12.6711Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1654_8171"
                      x1="0"
                      y1="12"
                      x2="24"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#00A2D6" />
                      <stop offset="1" stop-color="#0D4D80" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <div className="modalCard">
            <div className="modalContent">
              <button
                onClick={() =>
                  navigate("/statuspage", {
                    state: {
                      userGoals,
                      userFocus,
                      userPriorities,
                    },
                  })
                }
              >
                Kanban Board
              </button>
              <button
                onClick={() => {
                  navigate("/ninetydayplan", {
                    state: {
                      userGoals,
                      userFocus,
                      userPriorities,
                    },
                  });
                }}
              >
                Gantt Chart
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="tactic">
        <div className="tacticContent">
          <div
            className="main-breadcrumps px-4 py-4"
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {/* <a style={{ display: 'flex' }} onClick={() => navigate('/dashboardland ')}>
                Dashboard
                <Icon
                  icon="fa6-solid:greater-than"
                  color="#0d4d80"
                  width="18"
                  height="18"

                  className="icon"

                  style={{
                    marginLeft: "5px",
                    marginRight: "5px"
                  }}
                />

              </a> */}
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plan")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal ")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/swot ")}
            >
              Swot
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/medical ")}
            >
              Theme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/finaltheme ")}
            >
              Final Theme
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>

            <p className="breadCrumbs">Tactical Plan</p>
          </div>
          <div className="title">
            <h6>Update Tactical Plan </h6>
            <span> Just up to questions</span>
            <h4 className="mt-2 mb-2 text-center ">
              (You are viewing {plan?.planUser}'s Plan)
            </h4>
          </div>
          <div className="goalsContent">
            <div className="leftSide">
              <div className="leftContent">
                <div className="header">
                  <span>goals</span>
                </div>

                {userGoals
                  ? userGoals?.map((data) => {
                      return (
                        <div className="middle">
                          <span>{data}</span>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
            <div className="rightSide">
              <div className="leftContent">
                <div className="header">
                  <span>strategic focus</span>
                </div>
                {/* {console.log(finalThemeData.themes[1000])} */}

                <div className="middle">
                  <div>
                    {userFocus ? (
                      <span>
                        <button className="primary">Primary</button>
                        {finalThemeData.themes
                          ? finalThemeData.themes[1000]
                          : userFocus[0]}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="bottom">
                  {/* {console.log(userFocus)} */}

                  {userFocus ? (
                    <span>
                      <button className="primary">Secondary</button>
                      {userFocus[1]
                        ? userFocus[1]
                        : userFocus[0]
                        ? userFocus[0]
                        : "No Items Have Been Added In This Strategic"}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {userPriorities && (
            <TacticTest
              userPriorities={userPriorities}
              handleShow={handleShow}
              finalThemeData={finalThemeData}
              selectedData={location?.state?.selectedDropdownn}
            />
          )}
        </div>
      </div>

      <Modal
        show1={show1}
        onHide={handleClose1}
        dialogClassName="modal-90w"
        centered
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Continue with</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="modalBody">
          <div className="modal-header">
            <div className="titleModal">
              {" "}
              <span>Continue with</span>
            </div>
            <div className="modlclose">
              <span onClick={handleClose1}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    fill="url(#paint0_linear_1654_8171)"
                  />
                  <path
                    d="M11.5 12.6711L7.40114 16.77C7.24778 16.9233 7.0526 17 6.81559 17C6.57858 17 6.3834 16.9233 6.23004 16.77C6.07668 16.6166 6 16.4214 6 16.1844C6 15.9474 6.07668 15.7522 6.23004 15.5989L10.3289 11.5L6.23004 7.40114C6.07668 7.24778 6 7.0526 6 6.81559C6 6.57858 6.07668 6.3834 6.23004 6.23004C6.3834 6.07668 6.57858 6 6.81559 6C7.0526 6 7.24778 6.07668 7.40114 6.23004L11.5 10.3289L15.5989 6.23004C15.7522 6.07668 15.9474 6 16.1844 6C16.4214 6 16.6166 6.07668 16.77 6.23004C16.9233 6.3834 17 6.57858 17 6.81559C17 7.0526 16.9233 7.24778 16.77 7.40114L12.6711 11.5L16.77 15.5989C16.9233 15.7522 17 15.9474 17 16.1844C17 16.4214 16.9233 16.6166 16.77 16.77C16.6166 16.9233 16.4214 17 16.1844 17C15.9474 17 15.7522 16.9233 15.5989 16.77L11.5 12.6711Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1654_8171"
                      x1="0"
                      y1="12"
                      x2="24"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#00A2D6" />
                      <stop offset="1" stop-color="#0D4D80" />
                    </linearGradient>
                  </defs>
                </svg>
              </span>
            </div>
          </div>
          <div className="modalCard">
            <div className="modalContent">
              {user.role === "user" || user.role === "coach" ? (
                <button
                  onClick={() => {
                    navigate("/kanban", {
                      state: {
                        // strategicData,
                        userGoals,
                        StrategicFocus,
                        userPriorities,
                      },
                    });
                  }}
                >
                  Kanban Board
                </button>
              ) : (
                JSON.parse(
                  localStorage.getItem("Member")
                )?.permissions?.includes("Kanban Board") && (
                  <button
                    onClick={() => {
                      navigate("/ninetydayplan", {
                        state: {
                          userGoals,
                          userFocus,
                          userPriorities,
                        },
                      });
                    }}
                  >
                    Gant Chart
                  </button>
                )
              )}
            </div>
            <button
              onClick={() => {
                navigate("/kanban");
              }}
            >
              Kanban Board
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Tactic;
