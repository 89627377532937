import React, { useEffect, useState } from "react";
import PaginationComponent from "./components/Pagination";
import { Button, Form, Table } from "react-bootstrap";
import EditForm from "./components/EditForm";
import { formatDate, formatDateMMDDYYYY } from "./components/DateFormat";
import AddForm from "./components/Addform";
import axios from "axios";
import { toast } from "react-toastify";

const DataDisplay = ({ initialSheetData, sheetId }) => {
  // Separate data based on the presence of "Date of Need to Know" in the result
  const [sheetData, setSheetData] = useState(initialSheetData);
  const dataWithDate = [];
  const dataWithoutDate = [];
  const KpiSheetData = [];
  sheetData.forEach(({ sheetName, result }) => {
    if (result["Date of Need to Know"]) {
      dataWithoutDate.push({ sheetName, result });
    } else if (result["Date of Issue"]) {
      dataWithDate.push({ sheetName, result });
    } else if (result["Custom KPIs"]) {
      KpiSheetData.push({ sheetName, result });
    }
  });

  // Separate state for itemsPerPage for each table
  const [itemsPerPageIssueList, setItemsPerPageIssueList] = useState(5);
  const [itemsPerPageNeedToKnow, setItemsPerPageNeedToKnow] = useState(5);
  const [itemsPerPageKPI, setItemsPerPageKPI] = useState(5);

  const updateSheetData = (updatedSheetName, updatedRowData, isNew = false) => {
    setSheetData((prevSheetData) =>
      prevSheetData.map((sheet) => {
        if (sheet.sheetName === updatedSheetName) {
          const headers = Object.keys(sheet.result);

          if (isNew) {
            // Set 'Item #' to the current length of the array + 1
            const itemArrayLength = sheet.result["Item #"].length + 1;
            updatedRowData["Item #"] = itemArrayLength;

            // Add new row
            headers.forEach((header) => {
              sheet.result[header].push(updatedRowData[header]);
            });
          } else {
            // Update existing row
            const rowIndex = sheet.result[headers[0]].findIndex(
              (val) => val === updatedRowData[headers[0]]
            );
            headers.forEach((header) => {
              sheet.result[header][rowIndex] = updatedRowData[header];
            });
          }
        }
        return sheet;
      })
    );
  };

  return (
    <div>
      {/* Need to Know Table Configuration */}
      <div className="table-container p-2 shadow-lg">
        <h2 className="text-center my-3">Need to Knows</h2>
        <div className="leftside w-100 ">
          1.{" "}
          {/* <span className="text-decoration-underline fw-bold fs-4">
            Need To Knows:
          </span>{" "} */}
          Report in on noteworthy staff performance, problems or shifts; client
          developments; suppliers exceeding or failing to meet expectation.
          Wherever possible, tie these accolades or challenges to the Values.
          Add any issues to D3
        </div>
        <div className="d-flex justify-content-end">
          <Form.Group
            className="d-flex justify-content-end"
            controlId="itemsPerPageSelectNeedToKnow"
            style={{ width: "20%" }}
          >
            <Form.Label>Rows per page (Need to Knows):</Form.Label>
            <Form.Control
              as="select"
              value={itemsPerPageNeedToKnow}
              onChange={(e) =>
                setItemsPerPageNeedToKnow(Number(e.target.value))
              }
            >
              <option value={2}>2</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Form.Control>
          </Form.Group>
        </div>
        <DataTable
          data={dataWithoutDate}
          itemsPerPage={itemsPerPageNeedToKnow}
          updateSheetData={updateSheetData}
          sheetId={sheetId}
        />
      </div>
      {/* Issue List Table Configuration */}
      <div className="table-container p-2 shadow-lg">
        <h2 className="text-center my-3">Issue List</h2>
        <div className="leftside w-100">
          2. <span className="text-decoration-underline fw-bold fs-4">D3:</span>{" "}
          : (Discuss, Decide, Do: any issues that emerge from the above
          categories are to be discussed. From that discussion, either a
          decision is to be made or some action needs to be taken. If a decision
          is made, it can come off the D3 list. If a to do is identified, it
          remains on the list until the to do is completed AND the issue is
          resolved.)
        </div>
        <div className="d-flex justify-content-end">
          <Form.Group
            className="d-flex justify-content-end"
            controlId="itemsPerPageSelectIssueList"
            style={{ width: "20%" }}
          >
            <Form.Label>Rows per page (Issue List):</Form.Label>
            <Form.Control
              as="select"
              value={itemsPerPageIssueList}
              onChange={(e) => setItemsPerPageIssueList(Number(e.target.value))}
            >
              <option value={2}>2</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Form.Control>
          </Form.Group>
        </div>
        <DataTable
          data={dataWithDate}
          itemsPerPage={itemsPerPageIssueList}
          updateSheetData={updateSheetData}
          sheetId={sheetId}
        />
      </div>
      {/* KPI List Table Configuration */}
      <div className="table-container p-2 shadow-lg">
        <h2 className="text-center my-3">KPI List</h2>
        <div className="leftside w-100">
          3.{" "}
          <span className="text-decoration-underline fw-bold fs-4">
            OODA Loop:
          </span>{" "}
          : (Observe, Orient, Decide, Act. . Do you need to shift, allocate more
          resources, raise or lower a priority? In light of these new insights,
          is there is a new, better or revised choice? If so, then Act by
          adjusting the plan and put that into action.)
        </div>
        <div className="d-flex justify-content-end">
          <Form.Group
            className="d-flex justify-content-end"
            controlId="itemsPerPageSelectIssueList"
            style={{ width: "20%" }}
          >
            <Form.Label>Rows per page (KPI List):</Form.Label>
            <Form.Control
              as="select"
              value={itemsPerPageKPI}
              onChange={(e) => setItemsPerPageKPI(Number(e.target.value))}
            >
              <option value={2}>2</option>
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Form.Control>
          </Form.Group>
        </div>
        <DataTable
          data={KpiSheetData}
          itemsPerPage={itemsPerPageKPI}
          updateSheetData={updateSheetData}
          sheetId={sheetId}
        />
      </div>
    </div>
  );
};

const DataTable = ({ data, itemsPerPage, updateSheetData, sheetId }) => {
  const [pageStates, setPageStates] = useState(
    data.map(() => 1) // Initialize currentPage state for each table
  );

  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedSheetName, setSelectedSheetName] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [addHeaders, setAddHeaders] = useState([]);
  const [addSheetName, setAddSheetName] = useState("");

  if (!data || data.length === 0) return <p>No data available</p>;

  const handlePageChange = (pageNumber, tableIndex) => {
    const newPageStates = [...pageStates];
    newPageStates[tableIndex] = pageNumber;
    setPageStates(newPageStates);
  };

  const extractRowData = (headers, result, rowIndex, indexOfFirstItem) => {
    return headers.reduce((acc, header) => {
      acc[header] = result[header][indexOfFirstItem + rowIndex];
      return acc;
    }, {});
  };

  const handleActionClick = (rowData, sheetName) => {
    // Handle action button click here
    console.log("Action clicked for row:", sheetName, rowData);
    const formattedData = Object.keys(rowData).reduce((acc, key) => {
      if (key.includes("Date")) {
        acc[key] = formatDate(rowData[key]);
      } else {
        acc[key] = rowData[key];
      }
      return acc;
    }, {});
    setSelectedSheetName(sheetName);
    setSelectedData(formattedData);
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);
  const handleUpdate = async (sheetName, updatedData) => {
    const formattedData = Object.keys(updatedData).reduce((acc, key) => {
      if (key.includes("Date")) {
        acc[key] = formatDateMMDDYYYY(updatedData[key]);
      } else {
        acc[key] = updatedData[key];
      }
      return acc;
    }, {});
    // Update the data in the table
    // console.log(sheetId);
    // console.log("Sheet Name:", sheetName);
    // console.log("Updated Data:", formattedData);
    try {
      const res = await axios.post(`${apiUrl}/meeting/updateData`, {
        sheetId,
        sheetName,
        formattedData,
      });
      if (res.data.success) {
        updateSheetData(sheetName, formattedData);
      } else {
        toast.error("Faild to Update! try again..");
      }
      // console.log(res);
    } catch (error) {
      console.log(error.message);
    }
    // Here you would send updatedData and sheetName to your server or processing function
    handleCloseModal(); // Close modal after submission
  };

  //================= Handle Add data to Sheet ==================

  const handleAddClick = (headers, sheetName) => {
    setAddHeaders(headers);
    setAddSheetName(sheetName);
    setShowAddModal(true);
  };
  // console.log(sheetId);
  const handleAdd = async (sheetName, newData) => {
    // console.log(newData);
    const formattedData = Object.keys(newData).reduce((acc, key) => {
      if (key.includes("Date")) {
        acc[key] = formatDateMMDDYYYY(newData[key]);
      } else {
        acc[key] = newData[key];
      }
      return acc;
    }, {});
    // console.log(formattedData);
    // Define required fields
    // Define validation rules for different sets of fields
    const validationRules = [
      {
        requiredFields: ["Date of Issue"],
        fieldsToCheck: ["Date of Issue"],
      },
      {
        requiredFields: ["Date of Need to Know"],
        fieldsToCheck: ["Date of Need to Know"],
      },
    ];

    // Find the applicable validation rules based on the fields present in formattedData
    const applicableRules = validationRules.find((rule) =>
      rule.fieldsToCheck.some((field) => formattedData.hasOwnProperty(field))
    );

    if (applicableRules) {
      const missingOrEmptyFields = applicableRules.requiredFields.filter(
        (field) =>
          formattedData.hasOwnProperty(field) &&
          (formattedData[field] === undefined ||
            formattedData[field] === null ||
            formattedData[field].trim() === "")
      );

      if (missingOrEmptyFields.length > 0) {
        toast.error(`${missingOrEmptyFields.join(", ")} is missing or empty.`);
        return;
      }
    }

    // console.log(sheetName, formattedData);
    try {
      // const res = await axios.post(`http://localhost:3333/meeting/addData`, {
      const res = await axios.post(`${apiUrl}/meeting/addData`, {
        sheetId,
        sheetName,
        formattedData,
      });
      console.log(res.data);
      if (res.data?.success) {
        updateSheetData(sheetName, formattedData, true); // Pass true for isNew
      } else {
        toast.error("Faild to Add! try again..");
      }
    } catch (error) {
      console.log(error.message);
    }
    handleCloseAddModal();
  };

  const handleCloseAddModal = () => setShowAddModal(false);

  return (
    <div>
      {data.map(({ sheetName, result }, tableIndex) => {
        const headers = Object.keys(result);
        const totalRows = result[headers[0]].length;
        const totalPages = Math.ceil(totalRows / itemsPerPage);

        // Calculate the range of rows to display on the current page
        const currentPage = pageStates[tableIndex];
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentRows = headers.length
          ? result[headers[0]].slice(indexOfFirstItem, indexOfLastItem)
          : [];
        return (
          <div key={tableIndex}>
            <Button
              className="buttonAddStep mb-2"
              style={{ width: "12%" }}
              onClick={() => handleAddClick(headers, sheetName)}
            >
              Add New Row
            </Button>
            <div className="pagination-table">
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    {headers.map((header) => (
                      <th key={header}>{header}</th>
                    ))}
                    <th>Action</th> {/* Additional header for Action */}
                  </tr>
                </thead>
                <tbody>
                  {currentRows.map((_, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`${
                        result["Closed?"] &&
                        result["Closed?"][indexOfFirstItem + rowIndex] === "Yes"
                          ? "closedIssue"
                          : ""
                      }`}
                    >
                      {headers.map((header) => (
                        <td key={header}>
                          {result[header][indexOfFirstItem + rowIndex]}
                        </td>
                      ))}
                      <td>
                        <Button
                          className="buttonAddStep"
                          onClick={() =>
                            handleActionClick(
                              extractRowData(
                                headers,
                                result,
                                rowIndex,
                                indexOfFirstItem
                              ),
                              sheetName
                            )
                          }
                        >
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageChange={(page) => handlePageChange(page, tableIndex)}
            />
          </div>
        );
      })}
      {showModal && (
        <EditForm
          show={showModal}
          handleClose={handleCloseModal}
          initialData={selectedData}
          sheetName={selectedSheetName}
          handleUpdate={handleUpdate}
        />
      )}
      {showAddModal && (
        <AddForm
          show={showAddModal}
          handleClose={handleCloseAddModal}
          headers={addHeaders}
          sheetName={addSheetName}
          handleAdd={handleAdd}
        />
      )}
    </div>
  );
};
export default DataDisplay;
