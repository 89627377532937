import React, { useState, useEffect, useRef, useContext } from "react";
import "../../style/dashboard/dashboard.css";
import axios from "axios";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import { useQuery } from "react-query";
import Generic from "./dashboardcomp/Generic";
// import Generic from "./dashboardcomp/genericTest";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import arrow from "../../../src/assets/images/breadcrumbsArrow.png";
import { toast } from "react-toastify";
import { PlanContext } from "../../app/context";
import { visitedPath } from "../shared/visitedPath";

var apiUrl = process.env.REACT_APP_BACKEND_API;
// var apiUrl = "http://localhost:3333";
const fetchData = async (planID) => {
  if (!planID) {
    toast.info("first select the plan");
    return;
  }
  try {
    const response = await fetch(`${apiUrl}/question/allQuestion/${planID}`);
    const data = await response.json();
    return data;
  } catch (error) {
    // throw new Error("Error fetching data");
    console.log(error);
  }
};

const Dashboard = ({}) => {
  const { plan } = useContext(PlanContext);
  const planID = plan?._id;
  const navigate = useNavigate();
  const [fetch, setfetch] = useState(0);
  const [activeTab, setActiveTab] = useState("tab1");
  const [mobileWidth, setMobileWidth] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [openAccordion, setOpenAccordion] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [foundedAnswer, setFoundedAnswer] = useState();
  const [allAnswers, setAllAnswers] = useState([]);
  const [formDataState, setFormData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabinDb, setActiveTabInDb] = useState("");
  const tabRefs = useRef([]);

  const initialData = {
    id: 1,
    section: "What We Stand For",
    category: "Values",
    question: "Enter your Values.",
    swot_yes: "Strength",
    swot_no: "Weakness",
    // swot_third: "",
    // swot_fourth: "",
    comment_fourth: "",
    comment_yes: "We successfully live the value of ",
    comment_no: "We do not successfully live the value of ",
    comment_third: "",
    // model: "AnalysisValue",
    key: "values_not_living_it0",
    input: true,
    swot: "Strength",
    answer: "",
    isChecked: null,
    qNo: 525,
    plan: plan?._id,
  };

  const [values, setValues] = useState([initialData]);

  const { data, isLoading, isError, status, refetch } = useQuery(
    "myData",
    () => fetchData(planID) // Pass a function that returns fetchData(planID)
  );

  useEffect(() => {
    // visitedPath(plan?._id, { title: "SWOT", path: "/swot" });
    refetch();
  }, [fetch]);

  const activateActiveTab = async () => {
    try {
      const resp = await axios.get(`${apiUrl}/plan/getSinglePlan/${plan._id}`);
      console.log(resp.data);
      const activeTab = searchParams?.get("activeTab");
      if (!activeTab) {
        setSearchParams({ activeTab: resp?.data?.plan?.questionaireActiveTab });
        setActiveTab(resp?.data?.plan?.questionaireActiveTab);
      } else {
        setActiveTab(activeTab);
      }
      setActiveTabInDb(resp?.data?.plan?.questionaireActiveTab);
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    // Check if 'activeTab' is present in the query params
    const params = searchParams?.get("activeTab");
    if (params) {
      setActiveTab(params);
    }
  }, [searchParams, setSearchParams]);
  useEffect(() => {
    activateActiveTab();
  }, []);
  useEffect(() => {
    const activeTabRef = tabRefs.current.find((ref) => ref.id === activeTab);
    if (activeTabRef) {
      activeTabRef.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [activeTab]);

  function filterDataByCategory(dataArray, targetCategory) {
    if (dataArray?.length > 0) {
      return dataArray?.filter((item) => item.category === targetCategory);
    }
  }

  const purposeData = filterDataByCategory(data, "Purpose");
  const visionData = filterDataByCategory(data, "Vision");
  const missionData = filterDataByCategory(data, "Mission");
  const uspData = filterDataByCategory(data, "USP");
  const valuesData = filterDataByCategory(data, "Values");
  const productsData = filterDataByCategory(data, "Product and Service");
  const sellingData = filterDataByCategory(data, "Selling Physical Goods");
  const marketData = filterDataByCategory(data, "Marketing");
  const salesData = filterDataByCategory(data, "Sales");
  const followData = filterDataByCategory(data, "Follow The Customer");
  const productionData = filterDataByCategory(data, "Production");
  const deliveryData = filterDataByCategory(data, "Delivery");
  const postMartemData = filterDataByCategory(data, "Post-Mortem");
  const invoiceData = filterDataByCategory(data, "Invoicing & Billing");
  const physicalData = filterDataByCategory(data, "Physical Facilities");
  const inventoryData = filterDataByCategory(data, "Inventory Management");
  const financialData = filterDataByCategory(data, "Financial Management");
  const performanceData = filterDataByCategory(
    data,
    "Key Performance Indicators"
  );
  const currentData = filterDataByCategory(data, "Current Situation");
  const recruitmentData = filterDataByCategory(data, "Recruitment");
  const inductionData = filterDataByCategory(data, "Induction");
  const trainingData = filterDataByCategory(data, "Training");
  const compensationData = filterDataByCategory(data, "Compensation");
  const performanceManagmentData = filterDataByCategory(
    data,
    "Performance Management"
  );
  const subcontractorData = filterDataByCategory(data, "Subcontractor");
  const planningData = filterDataByCategory(data, "Planning");
  const personalData = filterDataByCategory(data, "Personal Growth");
  const threatsopportunitiesData = filterDataByCategory(
    data,
    "Pestle Analysis"
  );
  const impactData = filterDataByCategory(data, "Impact Assessment");
  const finalfiveData = filterDataByCategory(data, "The Final Five");

  const location = useLocation();
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (
      Number(tab.slice(3, tab.length)) >
      Number(activeTabinDb.slice(3, activeTabinDb.length))
    ) {
      setActiveTabInDb(tab);
    }
    if (planID && tab !== "tab31") {
      setSearchParams({ activeTab: tab });
    }
  };
  const handleDrawer = () => {
    setIsVisible(!isVisible);
  };
  useEffect(() => {
    const handleResize = () => {
      setMobileWidth(window.innerWidth);
    };

    handleResize(); // Set initial mobileWidth value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsVisible(mobileWidth && mobileWidth >= 1200);
  }, [mobileWidth]);
  const toggleAccordion = (eventKey) => {
    if (openAccordion === eventKey) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(eventKey);
    }
  };

  const isAccordionOpen = (eventKey) => {
    return openAccordion === eventKey;
  };

  if (activeTab === "tab31") {
    navigate("/result");
  }

  useEffect(() => {
    const findAnswer = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/answer/getAnswer/${planID}`
        );
        // console.log(response)
        setFormData(response?.data);
        setFoundedAnswer(response?.data);
        const ValueData = response?.data?.filter(
          (d) => d.comment_yes === "We successfully live the value of "
        );
        if (ValueData?.length > 0) {
          setValues(ValueData);
        }
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    };
    findAnswer(); // Call the function
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(`${apiUrl}/input/${planID}`);
        // console.log(resp.data)
        setInputValues(resp.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, []);

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  const tabs = [
    { id: "tab1", label: "The Long View", subLabel: "Purpose" },
    { id: "tab2", label: "The Long View", subLabel: "Vision" },
    { id: "tab3", label: "The Long View", subLabel: "Mission" },
    { id: "tab4", label: "What we stand for", subLabel: "USP" },
    { id: "tab5", label: "What we stand for", subLabel: "Values" },
    {
      id: "tab6",
      label: "Products and Services",
      subLabel: "Products and Services",
    },
    {
      id: "tab7",
      label: "Products and Services",
      subLabel: "Selling Physical Goods",
    },
    { id: "tab8", label: "Promotion", subLabel: "Marketing" },
    { id: "tab9", label: "Promotion", subLabel: "Sales" },
    { id: "tab10", label: "Processes", subLabel: "Follow the Customer" },
    { id: "tab11", label: "Processes", subLabel: "Production" },
    { id: "tab12", label: "Processes", subLabel: "Delivery" },
    { id: "tab13", label: "Processes", subLabel: "Post-Mortem" },
    { id: "tab14", label: "Processes", subLabel: "Invoicing & Billing" },
    { id: "tab15", label: "Processes", subLabel: "Physical Facilities" },
    { id: "tab16", label: "Processes", subLabel: "Inventory Management" },
    { id: "tab17", label: "Processes", subLabel: "Financial Management" },
    { id: "tab18", label: "Processes", subLabel: "Key Performance Indicator" },
    { id: "tab19", label: "People", subLabel: "Current Situation" },
    { id: "tab20", label: "People", subLabel: "Recruitment" },
    { id: "tab21", label: "People", subLabel: "Induction" },
    { id: "tab22", label: "People", subLabel: "Training" },
    { id: "tab23", label: "People", subLabel: "Compensation" },
    { id: "tab24", label: "People", subLabel: "Performance Management" },
    { id: "tab25", label: "People", subLabel: "Subcontractor" },
    { id: "tab26", label: "Planning", subLabel: "Planning" },
    { id: "tab27", label: "Personal Growth", subLabel: "Personal Growth" },
    {
      id: "tab28",
      label: "Other Consideration",
      subLabel: "Impact Assessment",
    },
    {
      id: "tab29",
      label: "Other Consideration",
      subLabel: "Addition Threats & Opportunities",
    },
    { id: "tab30", label: "Other Consideration", subLabel: "The Final Five" },
    { id: "tab31", label: "Completed", subLabel: "" },
  ];

  return (
    <>
      <Navbar />

      <section className="dashboard container-fluids container-space ">
        <div className="menuDiv">
          <button onClick={handleDrawer}></button>
        </div>

        <div className="general-content">
          <div className="main-breadcrumps px-3">
            <div
              className="dashboard-crumps d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <p
                className="breadCrumbs d-flex align-items-center"
                onClick={() => navigate("/plans")}
              >
                Plans
                <img className="arrowCrumbs" src={arrow} alt="" />
              </p>
              <p
                className="breadCrumbs"
                style={{ display: "flex" }}
                onClick={() => navigate("/questionnaire")}
              >
                Questionnaire
              </p>
            </div>
          </div>

          <h6>
            Business Questionnaire <br /> {plan?.planName}
          </h6>
          <h6 className="mt-2">(You are viewing {plan?.planUser}'s Plan)</h6>
        </div>

        <div className={isVisible ? "left trans-0 " : "left trans-0"}>
          <ul className="sidebarPAdding">
            {tabs.map((tab, index) => {
              const tabNumber = parseInt(tab.id.replace("tab", ""), 10);
              const activeTabNumber = parseInt(
                activeTabinDb.replace("tab", ""),
                10
              );
              const isDisabled = tabNumber > activeTabNumber;

              return (
                <li
                  key={tab.id}
                  id={tab.id}
                  ref={(el) => (tabRefs.current[index] = el)}
                  className={`tab subHeading ${
                    activeTab === tab.id ? "activetab" : ""
                  } ${isDisabled ? "disabled" : ""}`}
                  onClick={() => !isDisabled && handleTabChange(tab.id)}
                >
                  {tab.label} <br /> <span>{tab.subLabel}</span>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="w-100 pe-4"></div>

        <div className="right">
          {activeTab === "tab1" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={purposeData}
              status={status}
              category="Purpose | Why we do what we do "
              title="The Long View"
              find="Purpose"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab2" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={visionData}
              isLoading={isLoading}
              category="Vision | What Are We Trying To Create "
              title="The Long View"
              find="Vision"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab3" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={missionData}
              isLoading={isLoading}
              category="Mission | Who We Serve, What We Do & How "
              title="The Long View"
              find="Mission"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab4" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={uspData}
              isLoading={isLoading}
              category="USP | Our Unique Selling Proposition"
              title="What we stand for"
              find="USP"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab5" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={valuesData}
              isLoading={isLoading}
              category="Our Values"
              title="What we stand for"
              find="Values"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}

          {activeTab === "tab6" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={productsData}
              isLoading={isLoading}
              category="Product and Services"
              title="Products and Services"
              find="Product and Service"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab7" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={sellingData}
              isLoading={isLoading}
              category="For Those Selling Physical Goods (Only)"
              title="Product and Services"
              find="Selling Physical Goods"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab8" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={marketData}
              category="Marketing"
              title="Promotion"
              find="Marketing"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab9" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={salesData}
              category="Sales"
              title="Promotion"
              find="Sales"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab10" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={followData}
              category="Customer Intake | Follow the Customer"
              title="Processes"
              find="Follow The Customer"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab11" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={productionData}
              category="Production"
              title="Processes"
              find="Production"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab12" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={deliveryData}
              // category="Customer Intake | Follow the Customer"
              category="Delivery"
              title="Processes"
              find="Delivery"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab13" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={postMartemData}
              category="Post-Mortem"
              title="Processes"
              find="Post-Mortem"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab14" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={invoiceData}
              category="Invoicing/Billing"
              title="Processes"
              find="Invoicing & Billing"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab15" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={physicalData}
              category="Internal | Physical Facilities"
              title="Processes"
              find="Physical Facilities"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab16" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={inventoryData}
              category="Inventory Management"
              title="Processes"
              find="Inventory Management"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab17" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={financialData}
              category="Financial Management "
              title="Processes"
              find="Financial Management"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab18" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={performanceData}
              category="Key Performance Indicators & Other Considerations"
              title="Processes"
              find="Key Performance Indicators"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab19" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={currentData}
              category="Current Situation"
              title="People"
              find="Current Situation"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab20" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={recruitmentData}
              category="Recruitment"
              title="People"
              find="Recruitment"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab21" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={inductionData}
              category="Induction"
              title="People"
              find="Induction"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab22" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={trainingData}
              category="Training"
              title="People"
              find="Training"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab23" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={compensationData}
              category="Compensation"
              title="People"
              find="Compensation"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab24" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={performanceManagmentData}
              category="Performance Management"
              title="People"
              find="Performance Management"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab25" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={subcontractorData}
              category="Subcontractors & Other Considerations"
              title="People"
              find="Subcontractor"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab26" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={planningData}
              category="Planning"
              title="Planning"
              find="Planning"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab27" && (
            <Generic
              style={{ outline: "5px solid lime" }}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={personalData}
              category="Personal Growth"
              title="Personal Growth"
              find="Personal Growth"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab28" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={impactData}
              category="Impact Assessment"
              title="Other Consideration"
              find="Impact Assessment"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab29" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={threatsopportunitiesData}
              category="Additional Threat & Opportunities"
              title="Other Consideration"
              find="Pestle Analysis"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
          {activeTab === "tab30" && (
            <Generic
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={finalfiveData}
              category="The Final Five"
              title="Other Consideration"
              find="The Final Five"
              setfetch={setfetch}
              foundedAnswer={foundedAnswer}
              setFoundedAnswer={setFoundedAnswer}
              allAnswers={allAnswers}
              setAllAnswers={setAllAnswers}
              formDataState={formDataState}
              setFormData={setFormData}
              inputValues={inputValues}
              setInputValues={setInputValues}
              setValues={setValues}
              values={values}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setActiveTabInDb={setActiveTabInDb}
              activeTabinDb={activeTabinDb}
            />
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Dashboard;
