import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Theme from "../../page/theme";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import axios from "axios";
import { PlanContext, selectDropdownContext } from "../../app/context";
import { toast } from "react-toastify";
import { MdAddComment } from "react-icons/md";
import arrow from "../../../src/assets/images/breadcrumbsArrow.png";
import { visitedPath } from "../shared/visitedPath";
// import { Toast } from "react-toastify/dist/components";
var checkArray = [];
const Updatemedical = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { plan } = useContext(PlanContext);
  const [isLoading, setIsLoading] = useState(false);
  const [themes, setThemes] = useState([1, 2, 3, 4, 1000]);
  const [themeItems, setThemeItems] = useState({});
  const [themeNames, setThemeNames] = useState({});
  const [editingTheme, setEditingTheme] = useState(null);
  const [subSelectedCards, setSubSelectedCards] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedDropdownn, setselectedDropdownn] = useState([]);
  const [selectedCards, setSelectedCards] = useState();
  const [priority1, setPriority1] = useState("");
  const [priority2, setPriority2] = useState("");
  const [priority3, setPriority3] = useState("");
  const [priority4, setPriority4] = useState("");
  const [initialPriorities, setInitialPriorities] = useState({});
  const [strategicGoal1, setStrategicGoal1] = useState(null);
  const [strategicGoal2, setStrategicGoal2] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [priorityData, setPriorityData] = useState("");
  const [editedValue, setEditedValue] = useState("");
  const { selectDropDownData, setSelectDropDownData } = useContext(
    selectDropdownContext
  );
  useEffect(() => {
    const defaultThemeNames = {};
    themes.forEach((themeNumber) => {
      defaultThemeNames[themeNumber] = `Theme ${themeNumber}`;
    });
    setThemeNames(defaultThemeNames);
  }, []);
  // console.log(themeNames);

  const [finalThemeData, setFinalThemeData] = useState([]);
  const [allThemes, setAllThemes] = useState([]);
  const [clickedThemes, setClickedThemes] = useState({});

  const location = useLocation();
  // const { selectedCards } = location.state || {};

  useEffect(() => {
    visitedPath(plan?._id, {
      title: "Update Strategic Focus",
      path: "/medical",
    });
  }, [location.pathname]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  // const parsedPlan = JSON.parse(plan);

  const getQuestionareData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/questionnaire/${plan._id}`);

      const data = response?.data?.questionaires;
      // console.log(data);
      setSelectedCards(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getQuestionareData();
  }, []);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const DropdownComponent = (themeNumber) => {
    return (
      <div className="dropdown">
        <select onChange={(e) => setSelectedTheme(e.target.value)}>
          {themes.map((theme, index) => (
            <option key={index} value={theme}>
              Theme {themeNumber}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const addTheme = () => {
    const newThemeNumber = themes.length + 1;
    setThemes([...themes, newThemeNumber]);
    setThemeNames((prevThemeNames) => ({
      ...prevThemeNames,
      [newThemeNumber]: `Theme ${newThemeNumber}`,
    }));
  };
  // console.log(themes)
  const removeTheme = (themeNumber) => {
    // Remove the theme from themes
    const updatedThemes = themes.filter((theme) => theme !== themeNumber);
    setThemes(updatedThemes);
    // Remove the theme from themeNames
    const updatedThemeNames = { ...themeNames };
    delete updatedThemeNames[themeNumber];
    setThemeNames(updatedThemeNames);
  };

  const addItemToTheme = (themeNumber) => {
    const itemsForTheme = themeItems[themeNumber] || [];
    // setThemeItems({ ...themeItems, [themeNumber]: [...itemsForTheme, {}] });

    handleShow();
  };

  const removeItemFromTheme = (themeNumber, itemIndex) => {
    setselectedDropdownn((current) =>
      current.filter(
        (item, index) => !(item.theme === themeNumber && index === itemIndex)
      )
    );

    // selectedDropdownn?.filter(item => item.theme !== themeNumber);
  };
  const handleSendAllToFinalTheme = (themeNumber, index) => {
    if (!clickedThemes[themeNumber]) {
      const itemsToSend = selectedDropdownn.filter(
        (item) => item.theme === themeNumber
      );
      // console.log(itemsToSend)
      itemsToSend.forEach((item) => {
        item.theme = 1000;
        const duplicateItem = { ...item, theme: themeNumber };
        selectedDropdownn.push(duplicateItem);
      });

      setselectedDropdownn([...selectedDropdownn]);

      // Update the clicked state for this specific theme
      setClickedThemes((prevState) => ({
        ...prevState,
        [themeNumber]: true,
      }));
    }
  };
  const handleRemoveAllFromFinalTheme = (themeNumber, themeName, index) => {
    if (clickedThemes[themeNumber]) {
      const updatedDropdownn = selectedDropdownn.filter((item) => {
        if (item.themeName === themeName && item.theme === 1000) {
          return false;
        }
        return true;
      });
      setselectedDropdownn(updatedDropdownn);
      const updatedClickedThemes = { ...clickedThemes };
      delete updatedClickedThemes[themeNumber];
      setClickedThemes(updatedClickedThemes);
    }
  };

  const handleThemeNameChange = (themeNumber, newName) => {
    if (
      newName === "" ||
      newName === null ||
      newName === undefined ||
      newName.trim() === ""
    ) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    setThemeNames({ ...themeNames, [themeNumber]: newName });
  };

  const handleBlur = (themeNumber) => {
    handleThemeNameChange(themeNumber, editedValue);
  };

  const enableEdit = (themeNumber) => {
    if (editingTheme === themeNumber) {
      // If the same button is clicked again, toggle off the edit mode
      setEditingTheme(null);
    } else {
      // Enable edit mode for the clicked theme
      setEditingTheme(themeNumber);
    }
  };

  let items = [];

  const handlegoal1 = (event) => {
    setStrategicGoal1(event.target.value);
    // console.log(themeNames)
    setThemeNames({ ...themeNames, 1000: event.target.value });
  };

  const handlegoal2 = (event) => {
    setStrategicGoal2(event.target.value);
  };
  const handleMoveToTheme = (moveTo, item) => {
    const itemsToSend = selectedDropdownn.filter(
      (i) => i.theme === item.theme && i?._id === item?._id
    );
    itemsToSend.forEach((item) => {
      item.theme = Number(moveTo);
    });
    setselectedDropdownn([...selectedDropdownn]);
  };
  // console.log(selectedDropdownn);

  const ThemeCard = ({
    themeNumber,
    items,
    onAddItem,
    onRemoveTheme,
    onRemoveItem,
    priorityData,
    setPriorityData,
    ToFinalTheme,
    clickedThemes,
    RemoveFromFinalTheme,
  }) => (
    <div className="firstCard finalthemeCard">
      <div className="titleCard d-flex w-100 flex-wrap">
        <div
          className="titleLeft w-100 mb-2"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <>
            <div className="bottomright-header">
              <div className="headerLeft">
                <span
                  contentEditable
                  className="themesName"
                  style={{ outline: "none" }}
                  onClick={(e) => {
                    e.target.setAttribute("contentEditable", true);
                  }}
                  onBlur={(e) => {
                    {
                      e.target.setAttribute("contentEditable", false);
                      handleThemeNameChange(themeNumber, e.target.innerText);
                    }
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      themeNames[themeNumber] !== "Theme 1000"
                        ? themeNames[themeNumber]
                        : strategicGoal1
                        ? strategicGoal1
                        : "Final Theme",
                  }}
                ></span>
              </div>
              <div className="headerRight"></div>
            </div>
          </>
        </div>

        <div className="titleright">
          <button className="addButton" onClick={() => onAddItem(themeNumber)}>
            Add Items
          </button>
          {themeNumber < 1000 && (
            <>
              <button
                className="deletebtn"
                onClick={() => onRemoveTheme(themeNumber)}
              >
                Delete Theme{" "}
              </button>
              {!clickedThemes[themeNumber] ? (
                <button
                  onClick={() => ToFinalTheme(themeNumber)}
                  disabled={clickedThemes[themeNumber]}
                >
                  Add to Final Theme
                </button>
              ) : (
                <button
                  onClick={() =>
                    RemoveFromFinalTheme(themeNumber, `Theme ${themeNumber}`)
                  }
                >
                  Remove From Final Theme
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="cardBottom">
        {selectedDropdownn?.length === 0 ? (
          <span>No items have been added in this theme yet!</span>
        ) : (
          selectedDropdownn?.map((item, index) => {
            if (item.theme == themeNumber) {
              return (
                <>
                  <div key={index} className="firstCard2 w-100 ">
                    <div className="titleCard">
                      <div className="titleright"></div>
                    </div>
                    <div className="cardBottom">
                      <div className="systemButtons"></div>
                      <div
                        className="cardText "
                        style={{
                          background:
                            (item.swot == "Strength" &&
                              "var(--swotStrength)") ||
                            (item.swot == "Weakness" &&
                              "var(--swotWeakness)") ||
                            (item.swot == "Opportunity" &&
                              "var(--swotOpportunity)") ||
                            (item.swot == "Threat" && "var(--swotThreat)"),
                        }}
                      >
                        <p>
                          {item?.AnswersData?.swot === "Strength" &&
                            item?.AnswersData?.comment_yes != "" && (
                              <>
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#20AC27" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.question} ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.comment_yes && (
                                  <p>
                                    {item.AnswersData?.comment_yes}{" "}
                                    {item.AnswersData?.answer != "yes" &&
                                      item.AnswersData?.answer != "no" && (
                                        <p>{item?.AnswersData?.answer}</p>
                                      )}
                                    {(
                                      item?.inputFields ||
                                      item?.AnswersData?.inputFields
                                    )?.map((item, index) => (
                                      <ul
                                        style={{
                                          listStyle: "Disc",
                                          paddingLeft: "24px",
                                        }}
                                      >
                                        <li>{item?.answer}</li>
                                      </ul>
                                    ))}
                                  </p>
                                )}
                              </>
                            )}
                        </p>
                        <p>
                          {item?.AnswersData?.swot === "Weakness" &&
                            item?.AnswersData?.comment_no != "" && (
                              <>
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#F44336" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.question} ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.comment_no && (
                                  <p>
                                    {item.AnswersData?.comment_no}{" "}
                                    {item.AnswersData?.answer != "no" &&
                                      item.AnswersData?.answer != "yes" && (
                                        <p>
                                          {item?.AnswersData?.answer}
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </p>
                                      )}
                                  </p>
                                )}
                              </>
                            )}
                        </p>
                        <p>
                          {item?.AnswersData?.swot === "Opportunity" &&
                            (item?.AnswersData.comment_third != "" ||
                              item?.AnswersData.comment_no != "") && (
                              <>
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#0B5085" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.question} ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {(item?.AnswersData?.comment_third ||
                                  item?.AnswersData.comment_no) && (
                                  <p>
                                    {item?.AnswersData.comment_third
                                      ? item?.AnswersData.comment_third
                                      : item?.AnswersData.comment_no}{" "}
                                    {item?.AnswersData.answer != "yes" &&
                                      item?.AnswersData.answer != "no" &&
                                      item?.AnswersData.answer !=
                                        "notNeeded" && (
                                        <p>
                                          {item?.AnswersData?.answer}
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </p>
                                      )}
                                  </p>
                                )}
                              </>
                            )}
                        </p>
                        <p>
                          {item?.AnswersData?.swot === "Threat" &&
                            item?.AnswersData?.comment_fourth != "" && (
                              <>
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <div className="d-flex justify-content-end">
                                    <MdAddComment color="#FFC515" size={20} />
                                  </div>
                                )}
                                {item?.AnswersData?.questionType ===
                                  "AddedQuestion" && (
                                  <>
                                    <strong>
                                      {item?.AnswersData?.question} ?
                                    </strong>{" "}
                                    <br />
                                  </>
                                )}
                                {item?.AnswersData?.comment_fourth && (
                                  <p>
                                    {item?.AnswersData?.comment_fourth}{" "}
                                    {item?.AnswersData?.answer != "yes" &&
                                      item?.AnswersData.answer != "no" &&
                                      item?.AnswersData.answer !=
                                        "notNeeded" && (
                                        <p>
                                          {item?.AnswersData?.answer}
                                          {(
                                            item?.inputFields ||
                                            item?.AnswersData?.inputFields
                                          )?.map((item, index) => (
                                            <ul
                                              style={{
                                                listStyle: "Disc",
                                                paddingLeft: "24px",
                                              }}
                                            >
                                              <li>{item?.answer}</li>
                                            </ul>
                                          ))}
                                        </p>
                                      )}
                                  </p>
                                )}
                              </>
                            )}
                        </p>
                      </div>
                      <div className="bottomButton">
                        {themeNumber !== 1000 && (
                          <div className="">
                            <div className="">
                              <div className="dropdown">
                                <select
                                  onChange={(e) =>
                                    handleMoveToTheme(e.target.value, item)
                                  }
                                >
                                  <option disabled selected>
                                    Move to theme
                                  </option>
                                  {themes.map(
                                    (theme, index) =>
                                      item.theme !== theme && (
                                        <option key={index} value={theme}>
                                          {theme === 1000
                                            ? "Final Theme"
                                            : `Theme ${theme}`}
                                        </option>
                                      )
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                        <button
                          onClick={() =>
                            removeItemFromTheme(themeNumber, index)
                          }
                          className="removeItem addButton"
                        >
                          Remove Item
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })
        )}
      </div>
    </div>
  );

  const toggleCardSelection = (cardId) => {
    if (subSelectedCards.includes(cardId)) {
      setSubSelectedCards(subSelectedCards.filter((id) => id !== cardId));
    } else {
      setSubSelectedCards([...subSelectedCards, cardId]);
    }
  };

  function comparePriorities() {
    // Helper function to trim and check if a string is not empty
    const isValidString = (str) => str.trim() !== "";
    // Proceed with comparison if at least one priority is valid
    let foundMatch = false;

    // Check each pair of priorities
    if (
      isValidString(priority1) &&
      (priority1 === priority2 ||
        priority1 === priority3 ||
        priority1 === priority4)
    ) {
      console.log(`priority1 (${priority1}) matches with another priority.`);
      foundMatch = true;
    }
    if (
      isValidString(priority2) &&
      (priority2 === priority3 || priority2 === priority4)
    ) {
      console.log(`priority2 (${priority2}) matches with another priority.`);
      foundMatch = true;
    }
    if (isValidString(priority3) && priority3 === priority4) {
      console.log(`priority3 (${priority3}) matches with another priority.`);
      foundMatch = true;
    }

    // Log result based on whether a match was found
    if (foundMatch) {
      console.log("At least one matching pair of priorities found.");
    } else {
      console.log("No matching pairs of priorities found.");
    }

    return foundMatch;
  }
  const submitGoals = async () => {
    const isEqualPriority = comparePriorities();

    if (isEqualPriority) {
      toast.info("Priority Name must be unique");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(` ${apiUrl}/priority/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priority1: priority1 || "",
          priority2: priority2 || "",
          priority3: priority3 || "",
          priority4: priority4 || "",
          strategicGoal1: strategicGoal1 || "",
          strategicGoal2: strategicGoal2 || "",
          plan: plan._id,
          themes: themeNames,
          finalTheme: finalThemeData,
          selectedDropdownn: allThemes,
        }),
      });
      const data = await response.json();

      // console.log("The response is:", data);
      if (response.ok) {
        setIsLoading(false);
        navigate("/finaltheme", {
          state: {
            finalThemeData: finalThemeData,
            data: data,
          },
        });
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(`${apiUrl}/priority/${plan._id}`);
      const data = response.data;
      // console.log("The response is:", data);
      setPriorityData(data);
      setInitialPriorities({
        priority1: data.priority.priority1,
        priority2: data.priority.priority2,
        priority3: data.priority.priority3,
        priority4: data.priority.priority4,
      });
      if (data?.priority?.strategicGoal1) {
        setStrategicGoal1(data.priority.strategicGoal1);
      }

      if (data?.priority?.strategicGoal2) {
        setStrategicGoal2(data?.priority?.strategicGoal2);
      }

      if (data?.priority?.priority1) {
        setPriority1(data?.priority?.priority1);
      }

      if (data?.priority?.priority2) {
        setPriority2(data?.priority?.priority2);
      }

      if (data?.priority?.priority3) {
        setPriority3(data?.priority?.priority3);
      }

      if (data?.priority?.priority4) {
        setPriority4(data?.priority?.priority4);
      }
      if (data?.priority?.finalTheme) {
        setFinalThemeData(data?.priority?.finalTheme);
      }
      if (data?.priority?.selectedDropdownn) {
        // console.log(data?.priority?.selectedDropdownn);
        setselectedDropdownn(data?.priority?.selectedDropdownn);
      }
      if (data?.priority?.themes) {
        setThemeNames(data?.priority?.themes);

        const themeNumbersFromBackend = Object.keys(data?.priority?.themes).map(
          Number
        );

        // Update the themes state with the theme numbers from the backend data
        const updateThemesFromBackend = () => {
          setThemes(themeNumbersFromBackend);
        };
        console.log(themes);

        updateThemesFromBackend();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedDropdownn.length > 0) {
      setSelectDropDownData(selectedDropdownn);
    }
    getData();
  }, []);

  const dispatch = useDispatch();

  const ComposeFinalThemeData = () => {
    const filtered = selectedDropdownn.filter((item) => item.theme === 1000);
    setAllThemes(selectedDropdownn);
    setFinalThemeData(filtered);
  };

  useEffect(() => {
    ComposeFinalThemeData();
  }, [selectedDropdownn]);

  const handleSubmit = () => {
    // console.log(themeNames);
    // console.log("The final theme is:", finalThemeData);
    // console.log("The selectedDropdownn is:", selectedDropdownn);

    if (finalThemeData.length > 0 && allThemes.length > 0) {
      submitGoals();
    } else {
      console.log("No data");
    }
  };

  let currentLink = [];
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink.push(crumb);
      return (
        <div className="crumb" key={crumb}>
          <p>{crumb}</p>
        </div>
      );
    });

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Theme
            themes={themes}
            themeNames={themeNames}
            subSelectedCards={subSelectedCards}
            selectedCards={(selectedCards?.selected || []).sort(
              (a, b) => a.qNo - b.qNo
            )}
            toggleCardSelection={toggleCardSelection}
            selectedDropdownn={selectedDropdownn}
            setselectedDropdownn={setselectedDropdownn}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="updatemedical">
        <div className="medical-content">
          <div
            className="main-breadcrumps"
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/plan")}
            >
              Plans
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/questionnaire ")}
            >
              Questionnaire
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/goal ")}
            >
              Goals
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p
              className="breadCrumbs"
              style={{ display: "flex" }}
              onClick={() => navigate("/swot ")}
            >
              Swot
              <img className="arrowCrumbs" src={arrow} alt="" />
            </p>
            <p className="breadCrumbs">Theme</p>
          </div>
          <div className="medical-header">
            <h6>Update Strategic Focus </h6>
            <h6 className="mt-2 text-center ">
              (You are viewing {plan?.planUser}'s Plan)
            </h6>
          </div>
          <div className="innerContent">
            <div className="firstline-theme">
              <div className="themeTitle">
                <h6>Review Themes</h6>
              </div>
              <div className="themeCatagery">
                <div className="firstcatagery">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle cx="10" cy="10.5" r="10" fill="#9CCC65" />
                  </svg>{" "}
                  <h6> Strength</h6>
                </div>
                <div className="firstcatagery">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle cx="10" cy="10.5" r="10" fill="#EF5451" />
                  </svg>
                  <h6> Weakness</h6>
                </div>
                <div className="firstcatagery">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle cx="10" cy="10.5" r="10" fill="#0D4D80" />
                  </svg>
                  <h6>Opportunity</h6>
                </div>
                <div className="firstcatagery">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle cx="10" cy="10.5" r="10" fill="#FFC515" />
                  </svg>
                  <h6>Threat</h6>
                </div>
              </div>
            </div>

            <div className="medical-cards">
              <button className="addButton" onClick={addTheme}>
                Add Theme
              </button>
              <div className="medicalTop">
                {themes.map(
                  (themeNumber, index) =>
                    themeNumber < 1000 && (
                      <>
                        <ThemeCard
                          key={index}
                          themeNumber={themeNumber}
                          items={themeItems[themeNumber] || []}
                          onAddItem={addItemToTheme}
                          onRemoveItem={(themeNumber) =>
                            removeItemFromTheme(themeNumber, index)
                          }
                          onRemoveTheme={removeTheme}
                          priorityData={priorityData}
                          setPriorityData={setPriorityData}
                          ToFinalTheme={(themeNumber) =>
                            handleSendAllToFinalTheme(themeNumber, index)
                          }
                          RemoveFromFinalTheme={(themeNumber, themeName) =>
                            handleRemoveAllFromFinalTheme(
                              themeNumber,
                              themeName,
                              index
                            )
                          }
                          clickedThemes={clickedThemes}
                        />
                      </>
                    )
                )}
              </div>
              <div className="medicalBottom">
                <div className="bottomLeft">
                  <div className="bottomLeft-content">
                    <div className="title">
                      <h6>Our Strategic Focus & Goals</h6>
                      <span>Pick from the list </span>
                      <div className="select-boxes">
                        <div class="custom-select">
                          <select value={strategicGoal1} onChange={handlegoal1}>
                            <option value="" disabled selected>
                              Select Primary Strategic Focus
                            </option>
                            <option
                              value="Raise revenue by getting more people to pay"
                              className="options"
                            >
                              Raise revenue by getting more people to pay
                            </option>
                            <option
                              value="Raise revenue by getting more people to pay more
                              often"
                              className="options"
                            >
                              Raise revenue by getting more people to pay more
                              often
                            </option>
                            <option
                              value="Raise revenue by getting more people to pay more
                              per transaction"
                              className="options"
                            >
                              Raise revenue by getting more people to pay more
                              per transaction
                            </option>
                            <option
                              value="Reduce variable cost by process improvement"
                              className="options"
                            >
                              Reduce variable cost by process improvement
                            </option>
                            <option
                              value="Reduce overhead cost by cost reductions"
                              className="options"
                            >
                              Reduce overhead cost by cost reductions
                            </option>
                            <option
                              value="Reduce overhead cost by process improvement"
                              className="options"
                            >
                              Reduce overhead cost by process improvement
                            </option>
                            <option value="Reduce risk" className="options">
                              Reduce risk
                            </option>
                            <option
                              value="Personal Fulfilment"
                              className="options"
                            >
                              Personal Fulfilment
                            </option>
                            <option
                              value="Improve the performance of our people"
                              className="options"
                            >
                              Improve the performance of our people
                            </option>
                          </select>
                        </div>
                        <div class="custom-select">
                          <select
                            value={strategicGoal2}
                            onChange={handlegoal2}
                            required
                          >
                            <option value="" disabled selected>
                              Select Secondary Strategic Focus
                            </option>
                            <option
                              value="Raise revenue by getting more people to pay"
                              className="options"
                            >
                              Raise revenue by getting more people to pay
                            </option>
                            <option
                              value="Raise revenue by getting more people to pay more
                              often"
                              className="options"
                            >
                              Raise revenue by getting more people to pay more
                              often
                            </option>
                            <option
                              value="Reduce variable cost by cost reductions"
                              className="options"
                            >
                              Reduce variable cost by cost reductions
                            </option>
                            <option
                              value="Reduce variable cost by process improvement"
                              className="options"
                            >
                              Reduce variable cost by process improvement
                            </option>
                            <option
                              value="Reduce overhead cost by cost reductions"
                              className="options"
                            >
                              Reduce overhead cost by cost reductions
                            </option>
                            <option
                              value="Reduce overhead cost by process improvement"
                              className="options"
                            >
                              Reduce overhead cost by process improvement
                            </option>
                            <option value="Reduce risk" className="options">
                              Reduce risk
                            </option>
                            <option
                              value="Personal Fulfilment"
                              className="options"
                            >
                              Personal Fulfilment
                            </option>
                            <option
                              value="Improve the performance of our people"
                              className="options"
                            >
                              Improve the performance of our people
                            </option>
                          </select>
                        </div>
                        <div className="priorities-card">
                          <div className="title">
                            <h6>Choose Your Priorities</h6>
                          </div>
                          <div className="inputField-section">
                            <input
                              onChange={(e) => {
                                setPriority1(e.target.value);
                              }}
                              type="text"
                              placeholder="Type here"
                              value={priority1}
                            />
                            <input
                              onChange={(e) => {
                                setPriority2(e.target.value);
                                // setError('')
                              }}
                              type="text"
                              placeholder="Type here"
                              value={priority2}
                              // disabled={!!initialPriorities?.priority2}
                            />
                            <input
                              onChange={(e) => {
                                setPriority3(e.target.value);
                              }}
                              type="text"
                              placeholder="Type here"
                              value={priority3}
                              // disabled={!!initialPriorities?.priority3}
                            />
                            <input
                              onChange={(e) => {
                                setPriority4(e.target.value);
                              }}
                              type="text"
                              placeholder="Type here"
                              value={priority4}
                              // disabled={!!initialPriorities?.priority4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottomRight">
                  <div className="bottomright-header"></div>
                  <div className="bootomright-bottom">
                    {themes.map((themeNumber, index) => {
                      return (
                        themeNumber >= 1000 && (
                          <div className="">
                            <ThemeCard
                              key={index}
                              themeNumber={themeNumber}
                              items={themeItems[themeNumber] || []}
                              onAddItem={addItemToTheme}
                              onRemoveItem={(themeNumber) =>
                                removeItemFromTheme(themeNumber, index)
                              }
                              onRemoveTheme={removeTheme}
                              priorityData={priorityData}
                              setPriorityData={setPriorityData}
                              ToFinalTheme={(themeNumber) =>
                                handleSendAllToFinalTheme(themeNumber, index)
                              }
                            />
                          </div>
                        )
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="save-btn">
                {isLoading ? (
                  <div class="spinner"></div>
                ) : (
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Save Changes
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updatemedical;
