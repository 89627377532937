import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import { AuthContext, PlanContext, selectDropdownContext } from "./app/context";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./components/dashboard/Dashboard";
import Plan from "./page/Plan";
import AddQuestion from "./components/addquestion/AddQuestion";
import Questionnaire from "./page/Questionnaire";
import Goals from "./page/goals";
import Theme from "./page/theme";
import Medical from "./page/medical";
import Finaltheme from "./page/finaltheme";
import Plans from "./page/plans";
import Signin from "./page/signin";
import Signup from "./page/signup";
import Accountrecovery from "./page/accountrecovery";
import Resetpassword from "./page/resetpassword";
import Questionresult from "./page/questionresult";
import Tacticalplan from "./page/tacticalplan";
import Onepageplan from "./page/onepageplan";
import Ninetydplan from "./page/ninetydplan";
import Subscription from "./page/subscription";
import Startplan from "./page/startplan";
import Dashboardland from "./page/dashboardland";
import Statuspage from "./page/statuspage";
import Kanban from "./page/kanban";
import Success from "./components/payment/sucess";
import Cancel from "./components/payment/cancel";
import Admindashboard from "./components/Admindashboard/Admindashboard";
import Addcoach from "./components/Admindashboard/Addcoach";
import Profile from "./components/Admindashboard/Profile";
import CoachesTable from "./components/Admindashboard/CoachesTable";
import Adminplan from "./components/Admindashboard/adminplan/adminplan";
import Addteam from "./components/addteam/addteam";
import Chat from "./components/chat/chat";
import MeetingAgenda from "./components/meetingoctaloop/newMeetingAgenda";
import ProtectedRoute from "./middleware/authMiddleware";
import AccessDenied from "./middleware/adminRoutes";
import OtpPage from "./page/otp";
import Pagenotfound from "./page/pagenotfound/pagenotfound";
import AdminDashboardUserTable from "./components/Admindashboard/layout/userTable";
import AdminDashboardUserPlansTable from "./components/Admindashboard/layout/PlansTable";
import AcountSettings from "./components/Admindashboard/pages/acountSetting";
import NewDashboardTest from "./components/dashboard/NewTestQuestionPage";
// import PlanProtectedRoute from "./middleware/planMiddleware";

function App() {
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const [selectDropDownData, setSelectDropDownData] = useState("");
  return (
    <selectDropdownContext.Provider
      value={{ selectDropDownData, setSelectDropDownData }}
    >
      <div className="App">
        <ToastContainer />
        {/* routes start */}
        <Routes>
          <Route index element={<Signin />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/otpVerification/:email" element={<OtpPage />} />
          <Route path="/accountrecovery" element={<Accountrecovery />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
          {/* <Route path="/addquestion" element={<AddQuestion />} /> */}
          <Route
            element={user ? <ProtectedRoute /> : <Navigate to="/signin" />}
          >
            <Route path="/plan" element={<Plan />} />
            <Route path="/plans" element={<Plans />} />
            <Route element={plan ? <Outlet /> : <Navigate to="/plans" />}>
              <Route path="/swot" element={<Questionnaire />} />
              <Route path="/questionnaire" element={<Dashboard />} />
              {/* <Route path="/questionnaire" element={<NewDashboardTest />} /> */}
              <Route path="/goal" element={<Goals />} />
              <Route path="/theme" element={<Theme />} />
              <Route path="/medical" element={<Medical />} />
              <Route path="/finaltheme" element={<Finaltheme />} />
              <Route path="/result" element={<Questionresult />} />
              <Route path="/tacticalplan" element={<Tacticalplan />} />
              <Route path="/onepageplan" element={<Onepageplan />} />
              <Route path="/ninetydayplan" element={<Ninetydplan />} />
              <Route path="/statuspage" element={<Statuspage />} />
              <Route path="/startplan" element={<Startplan />} />
              <Route path="/kanban" element={<Kanban />} />
              <Route path="/chat" element={<Chat />} />
            </Route>
            <Route
              element={
                user?.isAdmin || user?.role === "admin" ? (
                  <Outlet />
                ) : (
                  <AccessDenied />
                )
              }
            >
              <Route path="/addcoach" element={<Addcoach />} />
              <Route path="/coachestable" element={<CoachesTable />} />
              <Route path="/admindashboard" element={<Admindashboard />} />
              <Route
                path="/adminplan/:invitetoken/:coach"
                element={<Adminplan />}
              />
            </Route>
            <Route path="/dashboardland" element={<Dashboardland />} />
            <Route
              path="/dashboard/admin"
              element={<AdminDashboardUserTable />}
            />
            <Route
              path="/dashboard/admin/settings"
              element={<AcountSettings />}
            />
            <Route
              path="/dashboard/admin/plan/:id"
              element={<AdminDashboardUserPlansTable />}
            />
            <Route path="/profile" element={<Profile />} />
            <Route path="/success" element={<Success />} />
            <Route path="/cancel" element={<Cancel />} />
            <Route path="/addteam" element={<Addteam />} />
            <Route path="/subscriptions" element={<Subscription />} />
            <Route path="/meetingagenda" element={<MeetingAgenda />} />
          </Route>
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </div>
    </selectDropdownContext.Provider>
  );
}

export default App;
