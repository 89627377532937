import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { toast } from "react-toastify";
import Chat from "../chat/chat";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { MdAddComment } from "react-icons/md";
import { PlanContext } from "../../app/context";
const TacticTest = ({
  userPriorities,
  handleShow,
  finalThemeData,
  selectedData,
}) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const { plan } = useContext(PlanContext);
  // console.log(priorities)
  // console.log(selectedData);
  // const [selectedDataDropDown, setselectedDataDropDown] = useState([]);
  // setselectedDataDropDown(selectedData);
  // console.log(finalThemeData);
  const initialData = [
    {
      planName: userPriorities[0],
      goals: [
        {
          goalName: "goal 1",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 2",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 3",
          tactics: [],
          editing: false,
        },
      ],
    },
    {
      planName: userPriorities[1],
      goals: [
        {
          goalName: "goal 1",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 2",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 3",
          tactics: [],
          editing: false,
        },
      ],
    },
    {
      planName: userPriorities[2],
      goals: [
        {
          goalName: "goal 1",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 2",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 3",
          tactics: [],
          editing: false,
        },
      ],
    },
    {
      planName: userPriorities[3],
      goals: [
        {
          goalName: "goal 1",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 2",
          tactics: [],
          editing: false,
        },
        {
          goalName: "goal 3",
          tactics: [],
          editing: false,
        },
      ],
    },
  ];
  const TacticTypes = [
    "New Lead Gen",
    "Repeat Lead Gen",
    "Conversion",
    "Avg Transaction Size",
    "Cost of Sale Reduction",
    "Overhead reduction",
  ];
  const [activeKey, setActiveKey] = useState(0);
  const [data, setData] = useState(initialData);
  const [tacticTexts, setTacticTexts] = useState({});
  const [aiInputBox, setAIinputBox] = useState({});
  const [selected, setSelectedOption] = useState({});
  const [selectedSecondOption, setSelectedSecondOption] = useState({});
  const [secondOptionData, setSecondOptionData] = useState([]);

  const h5Ref = useRef(null);

  const handleEditClick = () => {
    if (h5Ref.current) {
      h5Ref.current.focus();
    }
  };
  const handleAccordionToggle = (key) => {
    setActiveKey(key);
  };

  const addGoal = (priorityIndex) => {
    const newGoal = {
      goalName: `goal ${data[priorityIndex].goals.length + 1}`,
      tactics: [],
    };
    const newData = [...data];
    newData[priorityIndex].goals.push(newGoal);
    setData(newData);
  };

  const addTactic = (priorityIndex, goalIndex, value) => {
    const tacticText = value;
    if (!tacticText) return;

    const newTactic = {
      name: "Q1",
      type: "task",
      status: "scheduled",
      tactic: tacticText,
    };

    const newData = [...data];
    newData[priorityIndex]?.goals[goalIndex]?.tactics.push(newTactic);
    setData(newData);
    // Clear the input field after adding tactic
    setTacticTexts({ ...tacticTexts, [`${priorityIndex}-${goalIndex}`]: "" });
  };

  const handleTacticTextChange = (e, priorityIndex, goalIndex) => {
    const newText = e.target.value;
    setTacticTexts({
      ...tacticTexts,
      [`${priorityIndex}-${goalIndex}`]: newText,
    });
  };
  const handleAIinputBoxChange = (e, priorityIndex, goalIndex) => {
    const newValue = e.target.value;
    setAIinputBox({
      ...aiInputBox,
      [`${priorityIndex}-${goalIndex}`]: newValue,
    });
  };
  const handleQuarterChange = (e, priorityIndex, goalIndex, tacticIndex) => {
    const newQuarter = e.target.value;
    const newData = [...data];
    newData[priorityIndex].goals[goalIndex].tactics[tacticIndex].name =
      newQuarter;
    setData(newData);
  };
  const handleRemoveGoal = (priorityIndex, goalIndex) => {
    const newData = [...data];
    newData[priorityIndex].goals.splice(goalIndex, 1);
    setData(newData);
  };
  const removeTactic = (priorityIndex, goalIndex, tacticIndex) => {
    const newData = [...data];
    newData[priorityIndex].goals[goalIndex].tactics.splice(tacticIndex, 1);
    setData(newData);
  };

  const handleDoubleClick = (priorityIndex, goalIndex) => {
    const newData = [...data];
    newData[priorityIndex].goals[goalIndex].editing = true;
    setData(newData);
  };

  const handleBlur = (e, priorityIndex, goalIndex) => {
    const value = e.target.textContent;
    const newData = [...data];
    newData[priorityIndex].goals[goalIndex].goalName = value;
    newData[priorityIndex].goals[goalIndex].editing = false;
    setData(newData);
  };

  const handleSelectedBoxChange = async (priorityIndex, goalIndex, value) => {
    setSelectedOption({
      ...selected,
      [`${priorityIndex}-${goalIndex}`]: value,
    });

    try {
      const response = await axios.post(
        `${apiUrl}/tactics/get-tactics`,

        { type: value }
      );
      const data = response.data.tactics[0];
      setSecondOptionData(data.tacticsData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSecondSelectedBoxChange = (priorityIndex, goalIndex, value) => {
    setSelectedSecondOption({
      ...selectedSecondOption,
      [`${priorityIndex}-${goalIndex}`]: value,
    });
  };
  const handleAiGenerate = async (
    priority,
    priorityIndex,
    goalIndex,
    value
  ) => {
    // console.log(priority, priorityIndex, goalIndex, value);
    if (value === "") return;
    let processName = priority;
    let businessName = value;
    try {
      const response = await fetch(`${apiUrl}/chatbot/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ processName, businessName }),
      });
      // console.log(response, "response");
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      let data = await response.json();
      console.log(data);

      let startIndex = data.assistant.indexOf("[");
      // console.log(startIndex);

      let endIndex = data.assistant.lastIndexOf("]");
      // console.log(endIndex);

      if (startIndex == -1 || endIndex == -1) {
        // data = "[" + data + "]";
        data = "[" + data + "]" ? "[" + data + "]" : Array.isArray(data);
        console.error("Invalid string format: JSON array not found");

        return null;
      }

      let jsonArrayString = data.assistant?.substring(startIndex, endIndex + 1);
      // console.log(JSON.parse(jsonArrayString));
      const parsedData = JSON.parse(jsonArrayString);
      if (parsedData.length > 0) {
        parsedData.map((item, index) => {
          addTactic(priorityIndex, goalIndex, item);
        });
      }
    } catch (error) {
      toast.error("error while generating steps");
      console.error("Error making the API call:", error);
    }
  };
  const handleSave = async () => {
    // console.log(data);

    // console.log(plan._id);
    try {
      const resp = await axios.post(`${apiUrl}/ganttest`, {
        data: data,
        planId: plan._id,
        type: "tactic",
      });

      if (resp.data.success) {
        handleShow();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    const fetchTacticsData = async () => {
      try {
        if (plan) {
          const resp = await axios.get(`${apiUrl}/ganttest/${plan?._id}`);
          let res = resp.data;
          const updatedData = initialData.map((initialItem) => {
            const backendItem = res.find(
              (backendItem) => backendItem.planName === initialItem.planName
            );
            if (backendItem) {
              return backendItem; // Replace with backend object if planName exists
            } else {
              return initialItem; // Otherwise, keep the initial object
            }
          });
          setData(updatedData);
        } else {
          toast.info("First select plan");
        }
      } catch (error) {
        toast.error("somthing went wrong while getting tactical data");
      }
    };
    fetchTacticsData();
  }, []);
  return (
    <div className="accordan">
      <Accordion
        defaultActiveKey={0}
        className="accordianParent"
        onSelect={handleAccordionToggle}
      >
        <div className="">
          {data.map(
            (priority, priorityIndex) =>
              // console.log(priority) &&
              priority.planName !== undefined && (
                <Accordion.Item
                  eventKey={priorityIndex}
                  key={priorityIndex}
                  className={`accordianItem1 ${
                    activeKey == priorityIndex ? "active" : ""
                  }`}
                >
                  <Accordion.Header className="accordianHeader">
                    <div>{priority?.planName}</div>
                    <div className="svgContainer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="12"
                        viewBox="0 0 19 12"
                        fill="none"
                        className={`accordionIcon ${
                          activeKey === priorityIndex ? "active" : ""
                        }`}
                      >
                        <path
                          d="M2 2L9.5 9L17 2"
                          stroke={
                            activeKey === priorityIndex ? "white" : "black"
                          }
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="accordion-content accordianContent ">
                    <div className="card mb-4" key={priorityIndex}>
                      <div className="card-header bg-info text-white d-flex justify-content-between">
                        <h4>{priority?.planName}</h4>
                        <button
                          className="btn btnAddGoal"
                          onClick={() => addGoal(priorityIndex)}
                        >
                          Add Goal
                        </button>
                      </div>
                      <div className="card-body d-flex justify-content-between">
                        <div className="w-50">
                          <Chat
                            priority={priority.planName}
                            index={priorityIndex}
                            goals={data[priorityIndex].goals}
                            setData={setData}
                            data={data}
                            handleAiGenerate={handleAiGenerate}
                          />
                          <div className="leftside container">
                            {/* {console.log(selectedData)} */}
                            {selectedData
                              ? selectedData.map((data, index) => {
                                  // <div key={item._id}>

                                  if (data.theme == priority?.planName)
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          className="customerExperience mb-3"
                                          style={{
                                            background:
                                              (data.swot == "Strength" &&
                                                "var(--swotStrength)") ||
                                              (data.swot == "Weakness" &&
                                                "var(--swotWeakness)") ||
                                              (data.swot == "Opportunity" &&
                                                "var(--swotOpportunity)") ||
                                              (data.swot == "Threat" &&
                                                "var(--swotThreat)"),
                                          }}
                                        >
                                          {/* {console.log(
                                        data.AnswersData.AnswersData.category
                                      )} */}

                                          <div className="header">
                                            <span>
                                              {
                                                data?.AnswersData?.AnswersData
                                                  ?.category
                                              }
                                            </span>
                                          </div>
                                          {/* <p>{data.question}</p> */}
                                          <p>
                                            {data?.AnswersData?.AnswersData
                                              .swot === "Strength" &&
                                              data?.AnswersData?.AnswersData
                                                .comment_yes != "" && (
                                                <>
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <div className="d-flex justify-content-end">
                                                      <MdAddComment
                                                        color="#20AC27"
                                                        size={20}
                                                      />
                                                    </div>
                                                  )}
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <>
                                                      <strong>
                                                        {
                                                          data?.AnswersData
                                                            ?.AnswersData
                                                            ?.question
                                                        }{" "}
                                                        ?
                                                      </strong>{" "}
                                                      <br />
                                                    </>
                                                  )}
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    .comment_yes && (
                                                    <p>
                                                      {
                                                        data.AnswersData
                                                          ?.AnswersData
                                                          .comment_yes
                                                      }
                                                      {data.AnswersData
                                                        ?.AnswersData.answer !=
                                                        "yes" && (
                                                        <p>
                                                          {
                                                            data?.AnswersData
                                                              ?.AnswersData
                                                              .answer
                                                          }
                                                          {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                        </p>
                                                      )}
                                                    </p>
                                                  )}
                                                </>
                                              )}
                                          </p>
                                          <p>
                                            {data?.AnswersData?.AnswersData
                                              .swot === "Weakness" &&
                                              data?.AnswersData?.AnswersData
                                                .comment_no != "" &&
                                              data?.AnswersData?.AnswersData
                                                .comment_no && (
                                                <p>
                                                  {
                                                    data?.AnswersData
                                                      ?.AnswersData.comment_no
                                                  }{" "}
                                                  {data?.AnswersData
                                                    ?.AnswersData.answer !=
                                                    "no" && (
                                                    <p>
                                                      {data?.AnswersData
                                                        ?.AnswersData
                                                        ?.questionType ===
                                                        "AddedQuestion" && (
                                                        <div className="d-flex justify-content-end">
                                                          <MdAddComment
                                                            color="#F44336"
                                                            size={20}
                                                          />
                                                        </div>
                                                      )}
                                                      {data?.AnswersData
                                                        ?.AnswersData
                                                        ?.questionType ===
                                                        "AddedQuestion" && (
                                                        <>
                                                          <strong>
                                                            {
                                                              data?.AnswersData
                                                                ?.AnswersData
                                                                ?.question
                                                            }{" "}
                                                            ?
                                                          </strong>{" "}
                                                          <br />
                                                        </>
                                                      )}
                                                      {
                                                        data?.AnswersData
                                                          ?.AnswersData.answer
                                                      }
                                                      {data?.AnswersData?.AnswersData?.inputFields?.map(
                                                        (val, index) => (
                                                          // item.inputFields != '' &&
                                                          <ul
                                                            style={{
                                                              listStyle: "Disc",
                                                              paddingLeft:
                                                                "24px",
                                                            }}
                                                          >
                                                            <li>
                                                              {val?.answer}
                                                            </li>
                                                          </ul>
                                                          // console.log(item)
                                                        )
                                                      )}
                                                    </p>
                                                  )}
                                                </p>
                                              )}
                                          </p>
                                          <p>
                                            {data?.AnswersData?.AnswersData
                                              .swot === "Opportunity" &&
                                              (data?.AnswersData?.AnswersData
                                                ?.comment_third != "" ||
                                                data?.AnswersData?.AnswersData
                                                  ?.comment_no != "") && (
                                                <>
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <div className="d-flex justify-content-end">
                                                      <MdAddComment
                                                        color="#0B5085"
                                                        size={20}
                                                      />
                                                    </div>
                                                  )}
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <>
                                                      <strong>
                                                        {
                                                          data?.AnswersData
                                                            ?.AnswersData
                                                            ?.question
                                                        }{" "}
                                                        ?
                                                      </strong>{" "}
                                                      <br />
                                                    </>
                                                  )}
                                                  {(data?.AnswersData
                                                    ?.AnswersData
                                                    ?.comment_third ||
                                                    data?.AnswersData
                                                      .AnswersData
                                                      ?.comment_no) && (
                                                    <p>
                                                      {data?.AnswersData
                                                        ?.AnswersData
                                                        ?.comment_third
                                                        ? data?.AnswersData
                                                            ?.AnswersData
                                                            ?.comment_third
                                                        : data?.AnswersData
                                                            .AnswersData
                                                            .comment_no}
                                                      {data?.AnswersData
                                                        .AnswersData.answer !=
                                                        "yes" &&
                                                        data?.AnswersData
                                                          .AnswersData.answer !=
                                                          "no" &&
                                                        data?.AnswersData
                                                          .AnswersData.answer !=
                                                          "notNeeded" && (
                                                          <p>
                                                            {
                                                              data?.AnswersData
                                                                ?.AnswersData
                                                                .answer
                                                            }
                                                            {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                          </p>
                                                        )}
                                                    </p>
                                                  )}
                                                </>
                                              )}
                                          </p>
                                          <p>
                                            {data?.AnswersData?.AnswersData
                                              .swot === "Threat" &&
                                              data?.AnswersData?.AnswersData
                                                .comment_fourth != "" && (
                                                <>
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <div className="d-flex justify-content-end">
                                                      <MdAddComment
                                                        color="#FFC515"
                                                        size={20}
                                                      />
                                                    </div>
                                                  )}
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    ?.questionType ===
                                                    "AddedQuestion" && (
                                                    <>
                                                      <strong>
                                                        {
                                                          data?.AnswersData
                                                            ?.AnswersData
                                                            ?.question
                                                        }{" "}
                                                        ?
                                                      </strong>{" "}
                                                      <br />
                                                    </>
                                                  )}
                                                  {data?.AnswersData
                                                    ?.AnswersData
                                                    .comment_fourth && (
                                                    <p>
                                                      {
                                                        data?.AnswersData
                                                          ?.AnswersData
                                                          .comment_fourth
                                                      }
                                                      {data?.AnswersData
                                                        ?.AnswersData.answer !=
                                                        "yes" &&
                                                        data?.AnswersData
                                                          .AnswersData.answer !=
                                                          "no" &&
                                                        data?.AnswersData
                                                          .AnswersData.answer !=
                                                          "notNeeded" && (
                                                          <p>
                                                            {
                                                              data?.AnswersData
                                                                ?.AnswersData
                                                                .answer
                                                            }
                                                            {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                          </p>
                                                        )}
                                                    </p>
                                                  )}
                                                </>
                                              )}
                                          </p>
                                        </div>
                                      </>
                                    );
                                  // </div>
                                })
                              : finalThemeData[0]?.themes?.map(
                                  (data, index) => {
                                    // console.log(data.theme, priority.planName)
                                    if (data.theme == priority?.planName)
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="customerExperience mb-3"
                                            style={{
                                              background:
                                                (data.swot == "Strength" &&
                                                  "var(--swotStrength)") ||
                                                (data.swot == "Weakness" &&
                                                  "var(--swotWeakness)") ||
                                                (data.swot == "Opportunity" &&
                                                  "var(--swotOpportunity)") ||
                                                (data.swot == "Threat" &&
                                                  "var(--swotThreat)"),
                                            }}
                                          >
                                            <div className="header">
                                              <span>{priority?.planName}</span>
                                            </div>
                                            {/* <p>{data.question}</p> */}
                                            <p>
                                              {data?.AnswersData?.AnswersData
                                                .swot === "Strength" &&
                                                data?.AnswersData?.AnswersData
                                                  .comment_yes != "" && (
                                                  <>
                                                    {data?.AnswersData
                                                      ?.AnswersData
                                                      ?.questionType ===
                                                      "AddedQuestion" && (
                                                      <div className="d-flex justify-content-end">
                                                        <MdAddComment
                                                          color="#20AC27"
                                                          size={20}
                                                        />
                                                      </div>
                                                    )}
                                                    {data?.AnswersData
                                                      ?.AnswersData
                                                      .comment_yes && (
                                                      <p>
                                                        {
                                                          data.AnswersData
                                                            ?.AnswersData
                                                            .comment_yes
                                                        }
                                                        {data.AnswersData
                                                          ?.AnswersData
                                                          .answer != "yes" && (
                                                          <p>
                                                            {
                                                              data?.AnswersData
                                                                ?.AnswersData
                                                                .answer
                                                            }
                                                            {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                          </p>
                                                        )}
                                                      </p>
                                                    )}
                                                  </>
                                                )}
                                            </p>
                                            <p>
                                              {data?.AnswersData?.AnswersData
                                                .swot === "Weakness" &&
                                                data?.AnswersData?.AnswersData
                                                  .comment_no != "" &&
                                                data?.AnswersData?.AnswersData
                                                  .comment_no && (
                                                  <p>
                                                    {
                                                      data.AnswersData
                                                        ?.AnswersData.comment_no
                                                    }{" "}
                                                    {data.AnswersData
                                                      ?.AnswersData.answer !=
                                                      "no" && (
                                                      <p>
                                                        {data?.AnswersData
                                                          ?.AnswersData
                                                          ?.questionType ===
                                                          "AddedQuestion" && (
                                                          <div className="d-flex justify-content-end">
                                                            <MdAddComment
                                                              color="#F44336"
                                                              size={20}
                                                            />
                                                          </div>
                                                        )}
                                                        {
                                                          data?.AnswersData
                                                            ?.AnswersData.answer
                                                        }
                                                        {data?.AnswersData?.AnswersData?.inputFields?.map(
                                                          (val, index) => (
                                                            // item.inputFields != '' &&
                                                            <ul
                                                              style={{
                                                                listStyle:
                                                                  "Disc",
                                                                paddingLeft:
                                                                  "24px",
                                                              }}
                                                            >
                                                              <li>
                                                                {val?.answer}
                                                              </li>
                                                            </ul>
                                                            // console.log(item)
                                                          )
                                                        )}
                                                      </p>
                                                    )}
                                                  </p>
                                                )}
                                            </p>
                                            <p>
                                              {data?.AnswersData?.AnswersData
                                                .swot === "Opportunity" &&
                                                (data?.AnswersData?.AnswersData
                                                  ?.comment_third != "" ||
                                                  data?.AnswersData?.AnswersData
                                                    ?.comment_no != "") && (
                                                  <>
                                                    {data?.AnswersData
                                                      ?.AnswersData
                                                      ?.questionType ===
                                                      "AddedQuestion" && (
                                                      <div className="d-flex justify-content-end">
                                                        <MdAddComment
                                                          color="#0B5085"
                                                          size={20}
                                                        />
                                                      </div>
                                                    )}
                                                    {(data?.AnswersData
                                                      ?.AnswersData
                                                      ?.comment_third ||
                                                      data?.AnswersData
                                                        .AnswersData
                                                        ?.comment_no) && (
                                                      <p>
                                                        {data?.AnswersData
                                                          ?.AnswersData
                                                          ?.comment_third
                                                          ? data?.AnswersData
                                                              ?.AnswersData
                                                              ?.comment_third
                                                          : data?.AnswersData
                                                              .AnswersData
                                                              .comment_no}
                                                        {data?.AnswersData
                                                          .AnswersData.answer !=
                                                          "yes" &&
                                                          data?.AnswersData
                                                            .AnswersData
                                                            .answer != "no" &&
                                                          data?.AnswersData
                                                            .AnswersData
                                                            .answer !=
                                                            "notNeeded" && (
                                                            <p>
                                                              {
                                                                data
                                                                  ?.AnswersData
                                                                  ?.AnswersData
                                                                  .answer
                                                              }
                                                              {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                            </p>
                                                          )}
                                                      </p>
                                                    )}
                                                  </>
                                                )}
                                            </p>
                                            <p>
                                              {data?.AnswersData?.AnswersData
                                                .swot === "Threat" &&
                                                data?.AnswersData?.AnswersData
                                                  .comment_fourth != "" && (
                                                  <>
                                                    {data?.AnswersData
                                                      ?.AnswersData
                                                      ?.questionType ===
                                                      "AddedQuestion" && (
                                                      <div className="d-flex justify-content-end">
                                                        <MdAddComment
                                                          color="#FFC515"
                                                          size={20}
                                                        />
                                                      </div>
                                                    )}
                                                    {data?.AnswersData
                                                      ?.AnswersData
                                                      .comment_fourth && (
                                                      <p>
                                                        {
                                                          data?.AnswersData
                                                            ?.AnswersData
                                                            .comment_fourth
                                                        }
                                                        {data?.AnswersData
                                                          ?.AnswersData
                                                          .answer != "yes" &&
                                                          data?.AnswersData
                                                            .AnswersData
                                                            .answer != "no" &&
                                                          data?.AnswersData
                                                            .AnswersData
                                                            .answer !=
                                                            "notNeeded" && (
                                                            <p>
                                                              {
                                                                data
                                                                  ?.AnswersData
                                                                  ?.AnswersData
                                                                  .answer
                                                              }
                                                              {/* {(data?.AnswersData?.AnswersData?.inputFields)?.map((val, index) => (
                                                    // item.inputFields != '' &&
                                                    <ul style={{ listStyle: "Disc", paddingLeft: "24px" }}>
                                                      <li>
                                                        {val?.answer}
                                                      </li>
                                                    </ul>
                                                    // console.log(item)

                                                  ))} */}
                                                            </p>
                                                          )}
                                                      </p>
                                                    )}
                                                  </>
                                                )}
                                            </p>
                                          </div>
                                        </>
                                      );
                                  }
                                )}
                            {}
                          </div>
                        </div>
                        <div className="w-50">
                          {priority.goals.map((goal, goalIndex) => (
                            <div key={goalIndex} className="mb-3 card">
                              <div
                                className={`card-header goalContainer d-flex justify-content-between`}
                              >
                                <div className="d-flex align-items-center gap-3 ">
                                  <h5
                                    ref={h5Ref}
                                    contentEditable={goal.editing}
                                    suppressContentEditableWarning
                                    style={{
                                      outline: "none",
                                      padding: "1px",
                                      cursor: goal.editing ? "text" : "pointer",
                                      // Width: "200px",
                                    }}
                                    onDoubleClick={() =>
                                      handleDoubleClick(
                                        priorityIndex,
                                        goalIndex
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleBlur(e, priorityIndex, goalIndex)
                                    } // Pass the event to handleBlur
                                    id={`goal-${priorityIndex}-${goalIndex}`}
                                    dangerouslySetInnerHTML={{
                                      __html: goal.goalName,
                                    }}
                                  ></h5>
                                  {!goal.editing && (
                                    <FaEdit
                                      onClick={() => {
                                        handleDoubleClick(
                                          priorityIndex,
                                          goalIndex
                                        );
                                        requestAnimationFrame(() => {
                                          const element =
                                            document.getElementById(
                                              `goal-${priorityIndex}-${goalIndex}`
                                            );
                                          if (element) {
                                            element.focus();
                                          }
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  )}
                                </div>
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    handleRemoveGoal(priorityIndex, goalIndex)
                                  }
                                >
                                  Remove Goal
                                </button>
                              </div>
                              <div className="card-body">
                                <div className="input-group d-flex gap-2 mb-2">
                                  <select
                                    className="form-control"
                                    id="inputGroupSelect01"
                                    value={
                                      selected[
                                        `${priorityIndex}-${goalIndex}`
                                      ] || ""
                                    } // Assuming you have a state to store the selected value of the second select box
                                    onChange={(e) =>
                                      handleSelectedBoxChange(
                                        priorityIndex,
                                        goalIndex,
                                        e.target.value
                                      )
                                    } // Handle change of the second select box
                                    style={{ marginRight: "10px" }}
                                  >
                                    <option selected value="">
                                      Select Category
                                    </option>
                                    {/* <option value="option1">Option 1</option>
                                                                <option value="option2">Option 2</option>
                                                                <option value="option3">Option 3</option> */}
                                    {TacticTypes?.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    className="form-control"
                                    id="inputGroupSelect02"
                                    value={
                                      selectedSecondOption[
                                        `${priorityIndex}-${goalIndex}`
                                      ] || ""
                                    } // Assuming you have a state to store the selected value of the second select box
                                    onChange={(e) =>
                                      handleSecondSelectedBoxChange(
                                        priorityIndex,
                                        goalIndex,
                                        e.target.value
                                      )
                                    } // Handle change of the second select box
                                  >
                                    {/* Populate options based on your requirement */}
                                    <option selected value="">
                                      Select Tactic
                                    </option>
                                    {secondOptionData?.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                    {/* <option value="option1">Option 1</option>
                                                                <option value="option2">Option 2</option>
                                                                <option value="option3">Option 3</option> */}
                                  </select>
                                  {/* Button to change goal name */}
                                  <button
                                    className="input-group-append btn btn-sm btn-primary add"
                                    onClick={() => {
                                      const newGoalName =
                                        selectedSecondOption[
                                          `${priorityIndex}-${goalIndex}`
                                        ];
                                      if (newGoalName) {
                                        const newData = [...data];
                                        newData[priorityIndex].goals[
                                          goalIndex
                                        ].goalName = newGoalName;
                                        setData(newData);
                                      }
                                    }}
                                  >
                                    Select as Goal
                                  </button>
                                  <button
                                    className=" btn btn-sm btn-primary add"
                                    onClick={() =>
                                      addTactic(
                                        priorityIndex,
                                        goalIndex,
                                        selectedSecondOption[
                                          `${priorityIndex}-${goalIndex}`
                                        ]
                                      )
                                    }
                                  >
                                    Add Tactic
                                  </button>
                                </div>
                                <div className="input-group mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter tactic"
                                    value={
                                      tacticTexts[
                                        `${priorityIndex}-${goalIndex}`
                                      ] || ""
                                    }
                                    onChange={(e) =>
                                      handleTacticTextChange(
                                        e,
                                        priorityIndex,
                                        goalIndex
                                      )
                                    }
                                  />
                                  <button
                                    className="input-group-append btn btn-sm btn-primary add"
                                    onClick={() =>
                                      addTactic(
                                        priorityIndex,
                                        goalIndex,
                                        tacticTexts[
                                          `${priorityIndex}-${goalIndex}`
                                        ]
                                      )
                                    }
                                  >
                                    Add Tactic
                                  </button>
                                </div>
                                <div className="input-group mb-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter prompt here"
                                    value={
                                      aiInputBox[
                                        `${priorityIndex}-${goalIndex}`
                                      ] || ""
                                    }
                                    onChange={(e) =>
                                      handleAIinputBoxChange(
                                        e,
                                        priorityIndex,
                                        goalIndex
                                      )
                                    }
                                  />
                                  <button
                                    className="input-group-append btn btn-sm btn-primary add"
                                    onClick={() =>
                                      handleAiGenerate(
                                        priority.planName,
                                        priorityIndex,
                                        goalIndex,
                                        aiInputBox[
                                          `${priorityIndex}-${goalIndex}`
                                        ]
                                      )
                                    }
                                  >
                                    Generate By AI
                                  </button>
                                </div>
                                {goal.tactics.map((tactic, tacticIndex) => (
                                  <div
                                    key={tacticIndex}
                                    className="bg-light mb-2"
                                  >
                                    <div className="d-flex p-2 justify-content-between align-items-center">
                                      <p className="">{tactic.tactic}</p>
                                      <div className="d-flex gap-1 align-items-center justify-content-between">
                                        <select
                                          className="form-control"
                                          id={`quarterSelect-${priorityIndex}-${goalIndex}-${tacticIndex}`}
                                          value={tactic.name}
                                          onChange={(e) =>
                                            handleQuarterChange(
                                              e,
                                              priorityIndex,
                                              goalIndex,
                                              tacticIndex
                                            )
                                          }
                                        >
                                          <option value="Q1">Quarter 1</option>
                                          <option value="Q2">Quarter 2</option>
                                          <option value="Q3">Quarter 3</option>
                                          <option value="Q4">Quarter 4</option>
                                        </select>
                                        <button
                                          className="btn btn-danger col-auto remove"
                                          onClick={() =>
                                            removeTactic(
                                              priorityIndex,
                                              goalIndex,
                                              tacticIndex
                                            )
                                          }
                                        >
                                          remove step
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )
          )}
        </div>
      </Accordion>
      <div className="submitButton d-flex justify-content-end">
        <button className="tacticsave" onClick={handleSave}>
          Save Changes And Next
        </button>
      </div>
    </div>
  );
};

export default TacticTest;
