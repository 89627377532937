import React, { useState, useEffect, useRef, useContext } from "react";
import "./Actiondropdown.scss";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { AuthContext } from "../../../app/context";
const Actiondropdown = ({ plan, id, setPlans, setPlanData }) => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  const [isDropdownVisible, setDropdownVisibility] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("Actions");
  const { user } = useContext(AuthContext);
  console.log("user: ", user);
  const navigate = useNavigate();
  // const apiUrl = "http://localhost:3333";
  const dropdownRef = useRef(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleDropdown = () => {
    setDropdownVisibility(!isDropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisibility(false);
  };

  const handleThemeSelect = (theme) => {
    setSelectedTheme(theme);
    closeDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Cleanup the event listener on component unmount

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${apiUrl}/plan/${id}`);
      console.log(response.data);
      if (response.data.message === "Plan deleted successfully") {
        toast.success("Plan deleted successfully");
        handleClose();
        setPlans((item) => item.filter((i) => i._id !== id));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUserSelect = (path) => {
    setPlanData(plan);
    navigate(path);
  };
  const handleDuplicatePlan = async () => {
    try {
      const response = await axios.post(`${apiUrl}/plan/duplicatePlan`, {
        userId: user?.userId,
        planId: id,
      });
      console.log(response.data);
      if (response.data.success) {
        toast.success("Plan duplicated");
        setPlans((prevPlans) => [...prevPlans, response.data.newPlan]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="actionDropdown">
        <div className="actionDropdown-first">
          <div className="inputbox" onClick={toggleDropdown} ref={dropdownRef}>
            <div className="leftside">{selectedTheme}</div>
            <div className="rightside">
              {isDropdownVisible ? (
                <Icon
                  icon="fe:arrow-up"
                  color="#9ccc65"
                  width="18"
                  height="18"
                />
              ) : (
                <Icon
                  icon="fe:arrow-up"
                  color="#9ccc65"
                  width="18"
                  height="18"
                  rotate={2}
                />
              )}
            </div>
            {isDropdownVisible && (
              <div className="actionDropdown" onClick={closeDropdown}>
                {plan?.visitedPath?.map((path, index) => {
                  return (
                    <div
                      key={index}
                      className="firstdropdown"
                      onClick={() => handleUserSelect(path.path)}
                    >
                      <span>{path.title}</span>
                    </div>
                  );
                })}

                <div
                  className="firstdropdown"
                  style={{
                    background: "red",
                    color: "white",
                    borderBottom: "1px solid white",
                  }}
                >
                  <span onClick={handleShow}>Delete</span>
                </div>
                <div
                  className="firstdropdown"
                  style={{ background: "#1572BC" }}
                  onClick={handleDuplicatePlan}
                >
                  <span>Duplicate this plan</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this plan?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Actiondropdown;
