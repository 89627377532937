import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../shared/navbar";
import Footer from "../shared/footer";
import Minussign from "../../assets/images/minus.svg";
import "./meetingoctaloop.scss";
import {
  Alert,
  Button,
  Dropdown,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import StatusChart from "./StatusChart";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext, PlanContext } from "../../app/context";
import DataDisplay from "./meetingoctaloop";

const MeetingAgenda = () => {
  const apiUrl = process.env.REACT_APP_BACKEND_API;
  // const apiUrl = "http://localhost:3333";
  const { user } = useContext(AuthContext);
  const { plan } = useContext(PlanContext);
  const [meetingName, setMeetingName] = useState("Meeting Name");
  const [isEditable, setIsEditable] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(plan || null);
  const [statusCount, setStatusCount] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState(
    plan?.teamMembers || []
  );
  // const [data, setData] = useState([]);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [sheetTitles, setSheetTitles] = useState([]);
  const emailRef = useRef(null);
  //modal
  const [show, setShow] = useState(false);
  const [sheetId, setSheetId] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleClose = () => {
    setEmailError("");
    setEmail("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleAddTeamMember = async () => {
    if (!email) {
      setEmailError("user email is required.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    // Check if email is already in the team members list
    if (selectedMembers.includes(email)) {
      setEmailError("This email is already added to the team.");
      return;
    }
    // Clear the error if validation is successful
    setEmailError("");

    if (!selectedPlan?._id) {
      setEmailError("Please select plan First");
      return;
    }
    try {
      // Make a POST request to your API to add the team member
      const response = await axios.post(
        `${apiUrl}/plan/add-team-members`,
        { planId: selectedPlan?._id, userEmail: email },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`, // assuming you have the token for authentication
          },
        }
      );

      if (response.data.success) {
        // Handle success (e.g., update the UI, reset the form, etc.)
        toast.success("Team member added successfully");
        setSelectedMembers([...selectedMembers, email]); // Update your team members array
        setEmail(""); // Clear the email input after success
      } else {
        // Handle error from the server (e.g., user not found)
        setEmailError(
          response?.data?.message || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      // Handle network or unexpected errors
      toast.error(
        error.response.data.message ||
          "An error occurred while adding the team member. Please try again later."
      );
    } finally {
      setShow(false);
    }
  };

  const handleEditMeetingName = () => {
    setIsEditable(!isEditable);
  };

  // const getAllMembers = async () => {
  //   try {
  //     const resp = await axios.get(`${apiUrl}/members/${user.userId}`);
  //     setData(resp?.data?.members);
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };
  // const handleEmailChange = (e) => {
  //   const selectedEmail = e.target.value;
  //   // Find the selected member from data array
  //   const selectedMember = data.find(
  //     (member) => member.email === selectedEmail
  //   );
  //   // Add the selected member to selectedMembers array
  //   if (selectedMember) {
  //     setSelectedMembers((prevState) => [
  //       ...prevState,
  //       {
  //         _id: selectedMember._id,
  //         userName: selectedMember.userName,
  //         email: selectedMember.email,
  //       },
  //     ]);
  //     setData((prevState) =>
  //       prevState.filter((member) => member._id !== selectedMember._id)
  //     );
  //   }
  // };
  const handleChange = (event) => {
    setMeetingName(event.target.value);
  };

  const handleBlur = () => {
    setIsEditable(false);
  };

  const handleRemoveMember = async (userEmail) => {
    try {
      // Make a POST request to your API to add the team member
      const response = await axios.post(
        `${apiUrl}/plan/remove-team-members`,
        { planId: selectedPlan?._id, userEmail },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`, // assuming you have the token for authentication
          },
        }
      );

      if (response.data.success) {
        setShow(false);
        setSelectedMembers((prevState) =>
          prevState.filter((email) => email !== userEmail)
        );
        toast.success(response.data.message);
      } else {
        setShow(false);
        // Handle error from the server (e.g., user not found)
        toast.error(
          response?.data?.message || "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      // Handle network or unexpected errors
      toast.error(
        error.response.data.message ||
          "An error occurred while adding the team member. Please try again later."
      );
      setShow(false);
    }

    // const removedMember = selectedMembers.find((email) => email === userEmail);
    // if (removedMember) {
    //   setData((prevState) => [...prevState, removedMember]);
    // }
  };
  const fetchData = async () => {
    try {
      if (!plan || plan?.user === user?.userId) {
        const response = await axios.get(`${apiUrl}/plan/user`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });
        setPlans(response.data?.plans);
      } else {
        setPlans([plan]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    if (selectedPlan) {
      handleSelect(selectedPlan);
    }
    // getAllMembers();
  }, []);
  const handleSelect = async (plan) => {
    setSelectedPlan(plan);
    setSelectedMembers(plan?.teamMembers || []);
    if (plan?._id) {
      const resp = await axios.get(
        // `http://localhost:3333/ganttest/getStatus/${plan?._id}`
        `${apiUrl}/ganttest/getStatus/${plan?._id}`
      );
      setStatusCount(resp?.data);
    }
  };

  const handleFetchTitles = async () => {
    try {
      if (sheetId !== "") {
        setFetchSuccess(false);
        const resp = await axios.post(
          // `http://localhost:3333/meeting/getSheetData`,
          `${apiUrl}/meeting/getSheetData`,
          { sheetId }
        );
        setSheetTitles(resp.data);
        // if (resp.status === 200) {
        setFetchSuccess(true);
        // }
      } else {
        return null;
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
      // setFetchSuccess(false);
      console.log(error.message);
    }
  };
  const controlCopyEmail = () => {
    navigator.clipboard.writeText(emailRef.current.value);
    toast.success("Copied");
  };
  console.log(selectedPlan);
  return (
    <>
      <Navbar />
      <div className="meetingoctaloop">
        <div className="octaloop-content">
          <div className="content-header">
            <h6>
              {isEditable ? (
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={meetingName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                  <button
                    className="input-group-append btn btn-sm buttonAddStep add"
                    onClick={handleEditMeetingName}
                  >
                    save
                  </button>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <p onDoubleClick={handleEditMeetingName}>{meetingName}</p>
                  <FaEdit
                    cursor={PointerEvent}
                    onClick={handleEditMeetingName}
                  />
                </div>
              )}
            </h6>
          </div>
          <div className="d-flex justify-content-between">
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                className="meetingAgendaDropdown"
                id="dropdown-autoclose-true"
              >
                {selectedPlan ? selectedPlan.planName : "Select plan"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="meetingAgendadropdownMenu">
                {plans?.map((plan, index) => {
                  return (
                    <>
                      <Dropdown.Item
                        className="meetingAgendaDropdownItem"
                        key={plan?._id}
                        onClick={() => handleSelect(plan)}
                      >
                        {plan?.planName}
                      </Dropdown.Item>

                      {index < plans.length - 1 && <Dropdown.Divider />}
                    </>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Button variant="primary" onClick={handleShow}>
              Add Team Member
            </Button>
          </div>
          <div className="bottom-content mt-3">
            {statusCount[5]?.count > 0 ? (
              <StatusChart statusCount={statusCount} />
            ) : (
              <h2 className="mt-3 text-center">No task Added </h2>
            )}
          </div>
          {/* <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside">
                <span>Add members</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <select
                    value={selectedMembers[selectedMembers.length - 1]}
                    onChange={handleEmailChange}
                    style={selectBoxStyle}
                  >
                    <option value={"Select user email"} selected>
                      select user email
                    </option>
                    {data?.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div> */}

          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside">
                <span>Added members</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <table>
                    <tr>
                      <th>S.No</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>

                    {selectedMembers?.map((item, index) => (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item}</td>
                        <td>
                          <img
                            src={Minussign}
                            alt="..."
                            onClick={() => handleRemoveMember(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside w-100">
                1.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  SWIFT OR PB/BB
                </span>{" "}
                : (So, What I Feel Today. Or “Personal Best/Business Best”)
              </div>
            </div>

            <div className="firstsection">
              <div className="leftside w-100">
                2.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  Plan Execution Review (PER)
                </span>{" "}
                :{" "}
                <span className="text-primary fs-5 fw-bold">
                  Link to their plan
                </span>
                (Each exec member to label tasks as Green, Yellow, Red. If help
                needed, or change requested, move to D3 (Decide, Discuss or Do).
              </div>
            </div>

            <div className="firstsection">
              <div className="leftside w-100">
                3.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  KPI Update:
                </span>{" "}
                : (Depending on the nature of the KPI’s, this may be weekly, or
                monthly. Add any issues to D3)
              </div>
            </div>
          </div>

          {/* important notes section */}
          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside w-100">
                4.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  To Do Update:
                </span>{" "}
              </div>
            </div>
            <Alert variant="success">
              <Alert.Heading>Important Notes:</Alert.Heading>
              <ol>
                <li>Click the copy button below to copy the following link.</li>
                <li>Open your Google Sheet.</li>
                <li>
                  Navigate to the right side of the sheet and locate the{" "}
                  <strong>Share</strong> button.
                </li>
                <li>Click on the Share button.</li>
                <li>
                  {" "}
                  Go to the sharing settings and paste the link you just copied.
                  To paste, you can press the "Ctrl" and "V" keys at the same
                  time on your keyboard.{" "}
                </li>
                <li>
                  Set the permission to <strong>Editor</strong> to allow full
                  access and editing capabilities.
                </li>
              </ol>
              <hr />
              <div className="d-flex justify-content-between gap-3">
                <Form className="w-full">
                  <Form.Group controlId="formIssueTitle">
                    <Form.Control
                      type="text"
                      value={
                        "readdatafromgooglesheet@sacred-vigil-415606.iam.gserviceaccount.com"
                      }
                      disabled
                      ref={emailRef}
                    />
                  </Form.Group>
                </Form>
                <Button variant="outline-success" onClick={controlCopyEmail}>
                  Copy
                </Button>
              </div>
            </Alert>
          </div>
          <div className="content-secondsection">
            <div className="firstsection">
              <div className="leftside">
                <span>Google Sheet Link</span>
              </div>
              <div className="rightside">
                <div className="inputfield">
                  <input
                    type="text"
                    placeholder="http//:"
                    onChange={(e) => {
                      setSheetId(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className="buttonAddStep"
                style={{ width: "8%" }}
                onClick={handleFetchTitles}
              >
                Fetch sheet
              </button>
            </div>
          </div>
          {/* <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside w-100">
                1.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  Need To Knows:
                </span>{" "}
                : (Report in on noteworthy staff performance, problems or
                shifts; client developments; suppliers exceeding or failing to
                meet expectation. Wherever possible, tie these accolades or
                challenges to the Values. Add any issues to D3)
              </div>
            </div>
            <div className="firstsection">
              <div className="leftside w-100">
                2.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  D3:
                </span>{" "}
                : (Discuss, Decide, Do: any issues that emerge from the above
                categories are to be discussed. From that discussion, either a
                decision is to be made or some action needs to be taken. If a
                decision is made, it can come off the D3 list. If a to do is
                identified, it remains on the list until the to do is completed
                AND the issue is resolved.)
              </div>
            </div>

            <div className="firstsection">
              <div className="leftside w-100">
                3.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  ODA Loop:
                </span>{" "}
                : (Observe, Orient, Decide, Act. . Do you need to shift,
                allocate more resources, raise or lower a priority? In light of
                these new insights, is there is a new, better or revised choice?
                If so, then Act by adjusting the plan and put that into action.)
              </div>
            </div>
          </div> */}
          {fetchSuccess && (
            <DataDisplay initialSheetData={sheetTitles} sheetId={sheetId} />
          )}
          <div className="content-firstsection mt-3">
            <div className="firstsection">
              <div className="leftside w-100">
                2.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  Review Updated To-Do’s:
                </span>{" "}
                (Confirm all to do’s – both past ones that are incomplete and
                new ones – have an assigned person who is accountable and a
                deadline.)
              </div>
            </div>

            <div className="firstsection">
              <div className="leftside w-100 mt-3">
                3.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  Communication Plan:
                </span>{" "}
                (What, if any, communication from this meeting should be shared
                with the larger team? What is the message and who will deliver
                it?)
              </div>
            </div>

            <div className="firstsection">
              <div className="leftside w-100 mt-3">
                4.{" "}
                <span className="text-decoration-underline fw-bold fs-4">
                  Meeting Rating:
                </span>{" "}
                (1-10. What was the most value in the meeting? If not a 10, what
                could have been better.)
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Add Team Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
            <Form.Control
              placeholder="email"
              aria-label="email"
              aria-describedby="basic-addon1"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>
          {emailError && (
            <Alert variant="danger" className="p-1">
              {emailError}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleAddTeamMember}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  );
};

export default MeetingAgenda;
